import React, { useState } from "react";
import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart, CartesianGrid, LabelList, Line, LineChart, XAxis } from "recharts";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ChartContainer, ChartConfig, ChartTooltip, ChartTooltipContent } from "../ui/chart";

import { Typography, Button, IconButton } from "@mui/material";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

//Icons
import { ReactComponent as ChevronLeft } from "../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";
import { ReactComponent as TitleIcon } from "../../assets/icons/svg/chart-line.svg";

//Charts
import RadialChart from "../charts/RadialChart";
import HorizontalBarChart from "../charts/HorizontalBarChart";
import MultiAreaChart from "../charts/MultiAreaChart";

//Sections
import RecipeHistory from "./sections/RecipeHistory";
import Nutrition from "./sections/Nutrition";
import Expenses from "./sections/Expenses";

//Coming Soon Modal
import CustomModal from "../modal/CustomModal";
import ComingSoonModal from "../comingsoon/ComingSoonModal";

export const InsightsPage = () => {
  const navigate = useNavigate();
  const handleCloseComingSoon = () => {
    navigate("/");
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  //Todays macro targets at top
  //Planned meals
  //Daily macro history
  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
  };

  const formatDay = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  const changeDate = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    setCurrentDate(newDate);
  };

  return (
    <div className="py-[4rem] md:py-0 md:pl-[5rem]">
      <Helmet>
        <title>Insights</title>
      </Helmet>
      <CustomModal
        open={true}
        showExit={false}
        handleClose={handleCloseComingSoon}
        rounded="rounded-2xl"
        border={false}
        type="bottom"
        parentClassNameOverride="md:min-w-[600px]"
      >
        <ComingSoonModal />
      </CustomModal>
      <div className="w-full flex flex-col md:p-8 gap-4 bg-neutral-50">
        <div className="w-full flex flex-row justify-between border border-separator items-center p-4 md:rounded-md shadow-sm bg-background">
          <IconButton onClick={() => changeDate(-1)} className="p-0 hover:bg-transparent" disableRipple>
            <ChevronLeft className="h-4 fill-secondaryText" />
          </IconButton>
          <div className="w-full px-8">
            <div>
              <Typography variant="subtitle1" className="text-secondaryText leading-none">
                {formatDay(currentDate)}
              </Typography>
              <Typography variant="h5">{formatDate(currentDate)}</Typography>
            </div>
          </div>
          <IconButton onClick={() => changeDate(1)} className="p-0 hover:bg-transparent" disableRipple>
            <ChevronRight className="h-4 fill-secondaryText" />
          </IconButton>
        </div>
        <div className=" w-full flex flex-col xl:flex-row gap-4 xl:gap-8">
          <div className="w-full flex flex-col gap-16">
            <div className="w-full">
              <Nutrition currentDate={currentDate} />
            </div>
            <div className="w-full xl:hidden">
              <RecipeHistory />
            </div>
            <div className="w-full">
              <Expenses />
            </div>
          </div>
          <div className="w-full hidden xl:block">
            <RecipeHistory />
          </div>
        </div>
      </div>
    </div>
  );
};

InsightsPage.propTypes = {
  data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    data: state.data,
  };
};

export default connect(mapStateToProps)(InsightsPage);
