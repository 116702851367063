import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import api from "../../../api/api";

//Components
import Searchable from "../../layout/searchable/Searchable";

//Actions
import { hideRegisterModal } from "../../../actions/auth";
import { setSignupStage, restrictionsNext } from "../../../actions/register";

//MUI Components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const Restrictions = ({ hideRegisterModal, setSignupStage, restrictionsNext }) => {
  const [allergens, setAllergens] = useState([]);
  const [diets, setDiets] = useState([]);
  const [dislikes, setDislikes] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const onAllergenChange = (items) => {
    setAllergens(items);
    console.log(items);
  };

  const onDietChange = (items) => {
    setDiets(items);
  };

  const onDislikeChange = (items) => {
    setDislikes(items);
  };

  const fetchDietarySettings = async () => {
    try {
      const res = await api.get("/settings/dietary");

      if (res.data) {
        setAllergens(res.data.allergens);
        setDiets(res.data.diets);
        setDislikes(res.data.dislikes);
        setError(false);
      } else {
        setError(true);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
    //Fetch user's dietary settings
  };

  useEffect(() => {
    fetchDietarySettings();
  }, []);

  //TO DO:
  //Add allergens data + refine special diets data
  //Log data in state (on next)
  //Create backend route to store info
  //Move to next section
  return (
    <div className="flex items-center justify-center flex-col w-full">
      <div className="px-8 pt-8 md:pb-4 w-full">
        <div className="flex flex-col w-full mb-8">
          <Typography variant="h4" className="text-mainGreen font-bold">
            Dietary Restrictions
          </Typography>

          <Typography variant="subtitle2" className="font-normal">
            What can't you eat?
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4 mb-4">
          <Searchable
            label="Allergens"
            labelWidth={85}
            maxItems={30}
            multiple={true}
            freeSolo={true}
            apiUrl="/allergens/searchable"
            allowAdd={true}
            onItemChange={onAllergenChange}
            helperText="Add any allergens you are allergic to, such as peanuts or gluten"
            selectedItemsFromParent={allergens}
          />
        </div>
        <div className="w-full flex flex-col gap-4 mb-4">
          <Searchable
            label="Special Diets"
            labelWidth={115}
            multiple={true}
            maxItems={30}
            freeSolo={true}
            apiUrl="/diets/searchable"
            allowAdd={true}
            onItemChange={onDietChange}
            helperText="Add any special diets you follow, such as Vegan or Ketogenic"
            selectedItemsFromParent={diets}
          />
        </div>
        <div className="w-full flex flex-col gap-4 mb-4">
          <Searchable
            label="Dislikes"
            labelWidth={115}
            multiple={true}
            maxItems={30}
            freeSolo={true}
            apiUrl="/ingredients/searchable"
            allowAdd={true}
            onItemChange={onDislikeChange}
            helperText="Add any ingredients you dislike, and don't want any recipes containing them"
            selectedItemsFromParent={dislikes}
          />
        </div>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          size="large"
          className="my-4 bg-mainGreen text-background normal-case"
          onClick={() => restrictionsNext({ allergens, diets, dislikes })}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

Restrictions.propTypes = {
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  restrictionsNext: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { hideRegisterModal, setSignupStage, restrictionsNext })(Restrictions);
