import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";

//Config
import { SIGNUP_STAGES, isValidStage } from "./register/config/RegisterConfig";

// Components
import Restrictions from "./register/Restrictions";
import Preferences from "./register/Preferences";
import Ability from "./register/Ability";
import OAuthInfo from "./register/OAuthInfo";

// Actions
import { setSignupStage } from "../../actions/register";
import { loadUser } from "../../actions/auth";

const SignupStages = ({ auth: { isAuthenticated, loading, user }, register: { stage }, setSignupStage, loadUser }) => {
  const { stageId } = useParams();

  useEffect(() => {
    if (isAuthenticated && user) {
      if (user.signupStage && user.signupStage > 0) {
        setSignupStage(user.signupStage);
      }
    }
  }, [isAuthenticated, user, stageId]);

  // Redirect if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Redirect if signup is complete
  if (user && !user.signupStage) {
    return <Navigate to="/" />;
  }

  const renderStage = () => {
    if (!isValidStage(stageId)) {
      return <Navigate to="/" />;
    }

    switch (stageId) {
      case SIGNUP_STAGES.OAUTH_INFO:
        return <OAuthInfo />;
      case SIGNUP_STAGES.RESTRICTIONS:
        return <Restrictions />;
      case SIGNUP_STAGES.PREFERENCES:
        return <Preferences />;
      case SIGNUP_STAGES.ABILITY:
        return <Ability />;
      default:
        return <Navigate to="/" />;
    }
  };

  return (
    <div className="min-h-screen w-full bg-background">
      <div className="w-full md:max-w-screen-md mx-auto">{renderStage()}</div>
    </div>
  );
};
SignupStages.propTypes = {
  auth: PropTypes.object.isRequired,
  register: PropTypes.object.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  register: state.register,
});

export default connect(mapStateToProps, { setSignupStage, loadUser })(SignupStages);
