import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Button, IconButton } from "@mui/material";

import ReactPlayer from "react-player";
import CustomModal from "../../../../modal/CustomModal";

import { ReactComponent as VideoPlayIcon } from "../../../../../assets/icons/svg/clapperboard-play-solid.svg";

const SectionSteps = ({ walkthrough: { recipe, currentSection } }) => {
  const [hasAdditionalTiming, setHasAdditionalTiming] = useState(false);
  const [showTimingModal, setShowTimingModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleTimingModalClose = () => {
    setShowTimingModal(false);
  };

  const handleVideoModalClose = () => {
    setShowVideoModal(false);
  };

  const checkAdditionalTiming = () => {
    if (recipe.sections[currentSection].timing) {
      for (const key in recipe.sections[currentSection].timing) {
        if (key !== "total") {
          const timing = recipe.sections[currentSection].timing[key];
          if ((timing.minutes && timing.minutes > 0) || (timing.hours && timing.hours > 0)) {
            setHasAdditionalTiming(true);
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    checkAdditionalTiming();
  }, [recipe.sections[currentSection]]);
  return (
    <div className="w-full md:rounded-lg md:border md:border-separator bg-background">
      <CustomModal
        open={showVideoModal}
        handleClose={handleVideoModalClose}
        contentClassNameOverride="w-fit max-w-fit min-w-fit md:max-w-fit md:min-w-fit"
        type="bottom"
      >
        <ReactPlayer url={recipe?.sections[currentSection]?.video} />
      </CustomModal>
      <div className="flex flex-col">
        <div className="w-full bg-neutral-100 px-4 md:px-8 py-3 md:rounded-t-lg md:border-b md:border-separator flex flex-row gap-2 items-center">
          <Typography variant="h6" className="font-normal">
            Steps:
          </Typography>
          {recipe?.sections[currentSection]?.video && ReactPlayer.canPlay(recipe?.sections[currentSection]?.video) && (
            <IconButton
              onClick={() => setShowVideoModal(true)}
              className="py-0 px-2 hover:bg-transparent"
              disableRipple
            >
              <VideoPlayIcon className="h-4 fill-mainGreen-600" />
            </IconButton>
          )}
        </div>

        {recipe?.sections[currentSection]?.timing &&
          recipe?.sections[currentSection]?.timing?.total &&
          (recipe?.sections[currentSection]?.timing?.total?.hours > 0 ||
            recipe?.sections[currentSection]?.timing?.total?.minutes > 0) && (
            <div className="px-4 md:px-8 w-full border-b border-separator py-4 bg-background">
              <div className="flex flex-col gap-1">
                <Typography variant="subtitle2" component="p" className="font-normal leading-snug">
                  {` ${
                    recipe.sections[currentSection].timing.total.hours > 0
                      ? `${recipe.sections[currentSection].timing.total.hours} hrs `
                      : ""
                  }${recipe.sections[currentSection].timing.total.minutes} mins`}
                </Typography>
                {hasAdditionalTiming && (
                  <Button
                    className="p-0 hover:bg-transparent items-start justify-start"
                    disableRipple
                    onClick={() => setShowTimingModal(true)}
                  >
                    <Typography variant="subtitle2" className="text-mainGreen leading-none text-xs font-normal">
                      View Timing
                    </Typography>
                  </Button>
                )}
              </div>
            </div>
          )}
      </div>
      <div className="px-4 md:px-8 py-6">
        {recipe && currentSection !== null && recipe?.sections[currentSection]?.steps ? (
          recipe.sections[currentSection].steps.map((step, i) => {
            return (
              <div className="flex flex-row mb-2">
                <div>
                  <Typography variant="subtitle1" className="w-[1.5rem]">
                    {i + 1}.
                  </Typography>
                </div>
                <div>
                  <div>
                    <Typography variant="subtitle1">{step.text}</Typography>
                  </div>
                  {step && step.tip && (
                    <div className="text-secondaryText mt-1">
                      <Typography variant="subtitle1">Tip: {step.tip}</Typography>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <Typography>No steps</Typography>
        )}
      </div>
    </div>
  );
};

SectionSteps.propTypes = {
  walkthrough: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps, {})(SectionSteps);
