import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

//MUI
import { Typography, Button, TextField, Divider } from "@mui/material";

//Components
import RecipeCard from "../recipes/card/RecipeCard";
import RecipeCardSkeleton from "../recipes/card/RecipeCardSkeleton";
import Spinner from "../layout/Spinner";
import MainSection from "./components/main/MainSection";
import SelectableButtonGroup from "../layout/SelectableButtonGroup";
import CustomModal from "../modal/CustomModal";
import FiltersModal from "./components/modal/FiltersModal";

//Placeholder resources
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";

//Actions
import { getCookbook, recipeSearch, setCookbookQuery } from "../../actions/cookbook";
import { togglePlusUpgradeModal } from "../../actions/plus";
import PlusModal from "../plus/PlusModal";
//TO DO:
//Fetch recipes by section from DB
//Display recipes in each section
//Standalone section pages?
const Cookbook = ({ cookbook, getCookbook, recipeSearch, setCookbookQuery, plus, togglePlusUpgradeModal }) => {
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState("search");
  useEffect(() => {
    window.scrollTo(0, 0);
    getCookbook();
    setCookbookQuery("Search for a recipe...");
    return () => {
      console.log("EXIT");
      if (cookbook.search.query === "Search for a recipe..." || cookbook.search.query === "Generate a recipe...") {
        setCookbookQuery("");
      }
    };
  }, []);

  const handleSearch = () => {
    //Catch if user has not entered a search query, reset state
    if (cookbook.search.query === "Search for a recipe..." || cookbook.search.query === "Generate a recipe...") {
      setCookbookQuery("");
      recipeSearch("", cookbook.search.filters);
    } else {
      recipeSearch(cookbook.search.query, cookbook.search.filters);
    }
    navigate("/cookbook/search");
  };

  //Search Button Options
  const searchButtonOptions = [
    {
      label: "Search",
      onClick: () => {
        handleSearch();
      },
      onSelect: () => {
        setCookbookQuery("Search for a recipe...");
        setSearchType("search");
      },
    },
    {
      label: "Generate",
      onClick: () => {
        if (plus.isPlus) {
          navigate(`/create/recipe?type=generate&prompt=${cookbook.search.query}`);
        } else {
          togglePlusUpgradeModal(true);
        }
      },
      onSelect: () => {
        setCookbookQuery("Generate a recipe...");
        setSearchType("generate");
      },
    },
  ];

  const [showFilterModal, setShowFilterModal] = useState(false);

  const toggleFilterModal = (state) => {
    setShowFilterModal(state);
  };

  const handleSearchFocus = () => {
    if (cookbook.search.query === "Search for a recipe..." || cookbook.search.query === "Generate a recipe...") {
      setCookbookQuery("");
    }
  };

  const handleSearchBlur = () => {
    if (cookbook.search.query === "") {
      if (searchType === "search") {
        setCookbookQuery("Search for a recipe...");
      } else {
        setCookbookQuery("Generate a recipe...");
      }
    }
  };

  //State
  return (
    <div className="w-full bg-neutral-50 min-h-screen py-[4rem] mr-0 md:pb-4 md:pt-8 md:pl-[7rem]">
      <CustomModal open={showFilterModal} handleClose={() => toggleFilterModal(false)}>
        <FiltersModal handleClose={() => toggleFilterModal(false)} />
      </CustomModal>
      <CustomModal open={plus.modals.upgrade} handleClose={() => togglePlusUpgradeModal(false)} border={false}>
        <PlusModal handleClose={() => togglePlusUpgradeModal(false)} />
      </CustomModal>
      <div className="md:p-8 md:rounded-lg bg-background border-y -translate-y-1 pb-6 px-4 pt-4 mb-4 md:border border-separator min-h-[150px] flex flex-col justify-between md:mr-8 md:w-[calc(100%-2rem)] md:mb-8 md:shadow-sm">
        <TextField
          InputProps={{
            disableUnderline: true,
            classes: {
              input: "font-bold text-3xl md:text-4xl min-h-[50px] leading-tight",
            },
          }}
          value={cookbook.search.query}
          onChange={(e) => setCookbookQuery(e.target.value)}
          multiline
          maxRows={4}
          minRows={4}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          variant="standard"
          className="w-full h-full scrollbar"
        />
        <div className="flex flex-row justify-between items-end">
          <Button
            disableRipple
            className="hover:bg-transparent p-0 text-secondaryText leading-none"
            onClick={() => toggleFilterModal(true)}
          >
            Edit Search
          </Button>
          <div className="md:ml-16">
            <SelectableButtonGroup options={searchButtonOptions} buttonSize="large" buttonTextClasses="font-medium" />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-8 pl-4 md:pl-0">
        {cookbook && cookbook.results.loading ? (
          <Spinner classNameOverride="mt-8" />
        ) : (
          cookbook.results.subsectionOrder &&
          cookbook.results.subsectionOrder.map((subsection, i) => {
            return (
              <>
                {subsection === "onHand" && (
                  <>
                    {false && cookbook.results.onHand && (
                      <MainSection
                        reference="onHand"
                        title="On-Hand"
                        standaloneURL="onhand"
                        key={`${subsection}-${i}`}
                      />
                    )}
                  </>
                )}
                {subsection === "recentlyCooked" && cookbook.results.recentlyCooked && (
                  <MainSection
                    reference="recentlyCooked"
                    title="Recently Cooked"
                    standaloneURL="recent"
                    key={`${subsection}-${i}`}
                  />
                )}
                {subsection === "myRecipes" && cookbook.results.myRecipes && (
                  <MainSection
                    reference="myRecipes"
                    title="My Recipes"
                    standaloneURL="myrecipes"
                    key={`${subsection}-${i}`}
                  />
                )}
                {subsection === "bookmarked" && cookbook.results.bookmarked && (
                  <MainSection
                    reference="bookmarked"
                    title="Bookmarked"
                    standaloneURL="bookmarked"
                    key={`${subsection}-${i}`}
                  />
                )}
                {subsection === "lovedByFriends" && cookbook.results.lovedByFriends && (
                  <MainSection
                    reference="lovedByFriends"
                    title="Loved By Friends"
                    standaloneURL="loved"
                    key={`${subsection}-${i}`}
                  />
                )}
                {subsection === "trending" && cookbook.results.trending && (
                  <MainSection
                    reference="trending"
                    title="Trending"
                    standaloneURL="trending"
                    key={`${subsection}-${i}`}
                  />
                )}
                {subsection === "favourites" && cookbook.results.favourites && (
                  <MainSection
                    reference="favourites"
                    title="Favourites"
                    standaloneURL="favourites"
                    key={`${subsection}-${i}`}
                  />
                )}
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

Cookbook.propTypes = {
  getCookbook: PropTypes.func.isRequired,
  cookbook: PropTypes.object.isRequired,
  recipeSearch: PropTypes.func.isRequired,
  setCookbookQuery: PropTypes.func.isRequired,
  plus: PropTypes.object.isRequired,
  togglePlusUpgradeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cookbook: state.cookbook,
  plus: state.plus,
});

export default connect(mapStateToProps, { getCookbook, recipeSearch, setCookbookQuery, togglePlusUpgradeModal })(
  Cookbook
);
