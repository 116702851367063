import React from "react";

//Components
import ForYouGridManager from "./ForYouGridManager";

const ForYouGrid = ({ posts, columns }) => {
  return (
    <div className="grid min-[532px]:gap-2 md:gap-4 auto-fill-minmax-300">
      {posts.map((post) => (
        <ForYouGridManager key={post.id} post={post} />
      ))}
    </div>
  );
};

export default ForYouGrid;
