import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//API
import api from "../../api/api";

//MUI
import { TextField, Button, Typography, Slider, IconButton } from "@mui/material";

//Default Profile Picture
import { ReactComponent as DefaultPFP } from "../../assets/default/profile_picture.svg";

//Cropper
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../utils/image";

//Modal
import CustomModal from "../modal/CustomModal";

//Actions
import { updateProfileSuccess } from "../../actions/profile";
import { setAlert } from "../../actions/alert";

//Icons
import { ReactComponent as DeleteIcon } from "../../assets/icons/svg/trash.svg";

const EditProfile = ({ profile: { currentProfile }, updateProfileSuccess, setAlert }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: currentProfile && currentProfile.username ? currentProfile.username : "",
    picture: currentProfile && currentProfile.picture ? currentProfile.picture : "",
    name: currentProfile && currentProfile.name ? currentProfile.name : "",
    bio: currentProfile && currentProfile.bio ? currentProfile.bio : "",
    link: currentProfile && currentProfile.link ? currentProfile.link : "",
  });

  const [imagePreview, setImagePreview] = useState();

  const [profilePicFile, setProfilePicFile] = useState(null);

  const handleRemoveProfilePicture = () => {
    setImagePreview(""); // Clear the image preview
    setProfilePicFile(null); // Clear the file
  };
  //Error reporting
  const [errors, setErrors] = useState({
    username: "",
    name: "",
    bio: "",
    link: "",
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  //Cropping

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [cropLoading, setCropLoading] = useState(false);

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];

    // Validate file type and size
    if (file) {
      if (!file.type.startsWith("image/")) {
        setErrors({ ...errors, picture: "Please upload an image file" });
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        // 5MB limit
        setErrors({ ...errors, picture: "Image must be less than 5MB" });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setProfilePicFile(file);
        setShowCropper(true);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    setCropLoading(true);
    try {
      const croppedImageUrl = await getCroppedImg(imagePreview, croppedAreaPixels);

      // Convert URL to Blob
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();

      // Resize the image before creating the final file
      const resizedBlob = await resizeImageBlob(blob, 1024);

      const croppedFile = new File([resizedBlob], "profile-picture.png", {
        type: "image/png",
      });

      setProfilePicFile(croppedFile);
      setImagePreview(URL.createObjectURL(resizedBlob));
      setCropLoading(false);
      setShowCropper(false);
    } catch (e) {
      console.error(e);
      setCropLoading(false);
      setAlert("An error occurred while processing your image. Please try again.", "error");
      setErrors({ ...errors, picture: "Error processing image" });
    }
  };

  const resizeImageBlob = async (blob, maxSize) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        // Create canvas
        const canvas = document.createElement("canvas");
        canvas.width = maxSize;
        canvas.height = maxSize;

        // Draw and resize image on canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, maxSize, maxSize);

        // Convert to blob
        canvas.toBlob(
          (resizedBlob) => {
            if (!resizedBlob) {
              reject(new Error("Failed to resize image"));
              return;
            }
            resolve(resizedBlob);
          },
          "image/png",
          0.9
        );

        // Cleanup
        URL.revokeObjectURL(img.src);
      };

      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        reject(new Error("Failed to load image for resizing"));
      };

      img.src = URL.createObjectURL(blob);
    });
  };
  //Update state if currentProfile changes (usually first load)
  useEffect(() => {
    setFormData({
      username: currentProfile && currentProfile.username ? currentProfile.username : "",
      picture: currentProfile && currentProfile.picture ? currentProfile.picture : "",
      name: currentProfile && currentProfile.name ? currentProfile.name : "",
      bio: currentProfile && currentProfile.bio ? currentProfile.bio : "",
      link: currentProfile && currentProfile.link ? currentProfile.link : "",
    });
    setImagePreview(currentProfile && currentProfile.picture);
  }, [currentProfile]);

  useEffect(() => {
    // Cleanup function to revoke the object URL
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);
  /*
        TO DO:
        Image handling -> Upload/remove -> Open modal to crop image, send to S3 on form send
        Send form to backend and update profile (only if things were changed)
        Fetch profile, prefill values
        Use react-easy-crop to crop images to square?
        Edit Links Modal
        Pre-fill social links if signed up with a third party social?
        Error Reporting -> Username taken, etc
        Google WebRisk API for website links
        Wrap Picture in button to handle edit click on image
        Private Profile
        */

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    //Verification
    //Username
    const usernamePattern = /^[a-zA-Z0-9._-]{3,20}$/;
    if (!usernamePattern.test(formData.username)) {
      setErrors({ ...errors, username: "Invalid username" });
      return;
    }

    //Name
    if (formData.name.length > 100) {
      setErrors({ ...errors, name: "Invalid name" });
      return;
    }

    //Bio
    if (formData.bio.length > 256) {
      setErrors({ ...errors, bio: "Invalid bio" });
      return;
    }

    //Link
    if (formData.link.length > 256) {
      setErrors({ ...errors, link: "Invalid link" });
      return;
    }

    const submissionData = new FormData();
    submissionData.append("username", formData.username);
    submissionData.append("name", formData.name);
    submissionData.append("bio", formData.bio);
    submissionData.append("link", formData.link);

    if (profilePicFile) {
      submissionData.append("picture", profilePicFile); // Append the file for upload
    }

    if (imagePreview === "") {
      submissionData.append("picture", "");
    }

    try {
      //Catch and read 400 error to display to user
      const res = await api
        .post("/profile/edit", submissionData, {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure proper content type for file upload
          },
        })
        .catch((err) => {
          console.log(err.response.status === 400);
          if (err.response.status === 400) {
            console.log(err.response.data.errors);
            setErrors({
              username: err.response.data.errors.username ? err.response.data.errors.username : "",
              name: err.response.data.errors.name ? err.response.data.errors.name : "",
              bio: err.response.data.errors.bio ? err.response.data.errors.bio : "",
              link: err.response.data.errors.link ? err.response.data.errors.link : "",
            });
            setSubmitLoading(false);
          } else {
            return;
          }
        });

      // Handle response
      updateProfileSuccess(res.data);
      navigate(`/${res.data.username}`);
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
    }
  };

  const handleUsername = (e) => {
    //Trim and remove new lines
    if (e.target.value.length < 20) {
      setFormData({ ...formData, username: e.target.value });
    } else {
      setErrors({
        ...errors,
        username: "Username must be less than 20 characters",
      });
    }
  };

  const handleName = (e) => {
    if (e.target.value.length < 100) {
      setFormData({ ...formData, name: e.target.value });
    } else {
      setErrors({ ...errors, name: "Name must be less than 100 characters" });
    }
  };

  const handleBio = (e) => {
    const trimmed = e.target.value.replace(/(\n{3,})/g, "\n");
    if (trimmed.length < 256) {
      setFormData({ ...formData, bio: trimmed });
      setErrors({ ...errors, bio: "" });
    } else {
      setErrors({ ...errors, bio: "Bio must be less than 256 characters" });
    }
  };

  const handleLink = (e) => {
    const trimmed = e.target.value.trim().replace(/\n/g, "");
    if (trimmed.length < 256) {
      setFormData({ ...formData, link: trimmed });
    } else {
      setErrors({ ...errors, link: "Link must be less than 256 characters" });
    }
  };

  return (
    <div className="my-[4rem] md:my-0 md:ml-[5rem]">
      <CustomModal open={showCropper} handleClose={() => setShowCropper(false)} showExit={false}>
        <div className="">
          <div className="relative w-full h-[400px] bg-background rounded-lg overflow-hidden shadow-sm">
            <Cropper
              image={imagePreview}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              className="absolute top-0 left-0 w-full h-full"
              showGrid={false}
              cropShape="round"
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center py-4 px-8">
          <Button
            className="bg-mainGreen text-white hover:bg-mainGreen-600"
            onClick={handleCrop}
            size="small"
            fullWidth
            disabled={cropLoading}
          >
            Crop Image
          </Button>
        </div>
      </CustomModal>
      <div className="flex justify-center md:py-8 bg-neutral-50 min-h-screen">
        <div className="p-4 w-full max-w-[600px] md:p-8 bg-background md:rounded-lg border-y -translate-y-1 md:border border-separator h-fit">
          <div className="w-full flex flex-col items-center justify-around mb-4">
            {imagePreview === "" ? (
              <DefaultPFP className="w-[100px] h-[100px] object-cover rounded-full border border-primaryText" />
            ) : (
              <img
                alt={`${formData.username}'s profile picture`}
                src={imagePreview}
                className="w-[100px] h-[100px] object-cover rounded-full border border-primaryText"
              ></img>
            )}

            <div className="flex flex-row items-center justify-center gap-2 mt-2">
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleProfilePicChange}
              />
              <label htmlFor="raised-button-file">
                <Button
                  component="span"
                  variant="outlined"
                  color="secondary"
                  className="text-mainGreen mt-2 hover:bg-transparent"
                  disableRipple
                >
                  Edit Profile Picture
                </Button>
              </label>
              {imagePreview !== "" && (
                <IconButton
                  disableRipple
                  onClick={handleRemoveProfilePicture}
                  className="text-rose-600 hover:bg-transparent mt-2"
                >
                  <DeleteIcon className="h-4 w-auto fill-rose-600" />
                </IconButton>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit} method="post" enctype="multipart/form-data">
            <div>
              <div className="flex flex-row items-center mb-4">
                <TextField
                  label="Username"
                  fullWidth
                  value={formData.username}
                  onChange={(e) => handleUsername(e)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={errors.username !== ""}
                  helperText={errors.username}
                />
              </div>
              <div className="flex flex-row items-center mb-4">
                <TextField
                  label="Name"
                  fullWidth
                  value={formData.name}
                  onChange={(e) => handleName(e)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={errors.name !== ""}
                  helperText={errors.name}
                />
              </div>
              <div className="flex flex-row items-center mb-4">
                <TextField
                  label="Bio"
                  fullWidth
                  multiline
                  value={formData.bio}
                  onChange={(e) => handleBio(e)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={errors.bio !== ""}
                  helperText={errors.bio}
                />
              </div>
              <div className="flex flex-row items-center mb-4">
                <TextField
                  label="Link"
                  fullWidth
                  value={formData.link}
                  onChange={(e) => handleLink(e)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={errors.link !== ""}
                  helperText={errors.link}
                />
              </div>
            </div>
          </form>

          <Button
            type="submit"
            variant="contained"
            disableElevation
            color="primary"
            className="mt-4"
            onClick={handleSubmit}
            fullWidth
            disabled={submitLoading}
          >
            Update Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

EditProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  updateProfileSuccess: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { updateProfileSuccess, setAlert })(EditProfile);
