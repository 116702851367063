import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ReactComponent as EditIcon } from "../../../../assets/icons/svg/pen-sharp.svg";
import { ReactComponent as CannotEditIcon } from "../../../../assets/icons/svg/pen-slash-sharp.svg";

//Actions
import { openImageCropModal } from "../../../../actions/media";
import { setAlert } from "../../../../actions/alert";
const ReorderImage = ({ image, index, openImageCropModal, setAlert }) => {
  console.log(image);

  const handleDeleteImage = () => {};

  const handleClick = () => {
    //If non-imported image, open crop modal
    openImageCropModal(index);
  };

  return (
    <div className="w-[150px] h-[150px] relative" onClick={() => handleClick(index)}>
      <img
        src={image && image.cropped ? image.cropped.croppedImage : image.previewUrl}
        alt={`preview ${index}`}
        className="w-full h-full object-cover absolute"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center opacity-0 transition-opacity duration-300 hover:opacity-100">
        <EditIcon className="h-6 fill-white" />
      </div>
    </div>
  );
};

ReorderImage.propTypes = {
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  openImageCropModal: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { openImageCropModal, setAlert })(ReorderImage);
