//NavigationListener to dispatch navigation hooks from a component
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { NAVIGATE_RESET } from "../../actions/types";

const NavigationListener = () => {
  const navigate = useNavigate();
  const navigationPath = useSelector((state) => state.navigation.path);
  const dispatch = useDispatch();
  useEffect(() => {
    if (navigationPath) {
      navigate(navigationPath);
      dispatch({ type: NAVIGATE_RESET });
    }
  }, [navigationPath, navigate]);

  return null;
};

export default NavigationListener;
