import React from "react";
import { Typography } from "@mui/material";
const EmbeddableVideoModal = () => {
  return (
    <div className="">
      <Typography variant="h6" className="mb-4 px-4 font-normal">
        Supported Video Platforms
      </Typography>

      <div className="mb-4 px-4">
        <Typography variant="subtitle2" className="font-medium mb-2">
          YouTube
        </Typography>
        <Typography variant="body2" className="text-secondaryText mb-1">
          • Regular links: youtube.com/watch?v=xxxxx
        </Typography>
        <Typography variant="body2" className="text-secondaryText mb-1">
          • Short links: youtu.be/xxxxx
        </Typography>
        <Typography variant="body2" className="text-secondaryText">
          • Shorts: youtube.com/shorts/xxxxx
        </Typography>
      </div>

      <div className="mb-4 px-4">
        <Typography variant="subtitle2" className="font-medium mb-2">
          Facebook
        </Typography>
        <Typography variant="body2" className="text-secondaryText mb-1">
          • Watch links: facebook.com/watch?v=xxxxx
        </Typography>
        <Typography variant="body2" className="text-secondaryText">
          • Video links: facebook.com/username/videos/xxxxx
        </Typography>
      </div>

      <div className="mb-4 px-4">
        <Typography variant="subtitle2" className="font-medium mb-2">
          Vimeo
        </Typography>
        <Typography variant="body2" className="text-secondaryText">
          • Regular links: vimeo.com/xxxxx
        </Typography>
      </div>

      <div className="mb-4 px-4">
        <Typography variant="subtitle2" className="font-medium mb-2">
          Native Video Formats
        </Typography>
        <Typography variant="body2" className="text-secondaryText mb-1">
          • MP4 files: .mp4
        </Typography>
        <Typography variant="body2" className="text-secondaryText mb-1">
          • WebM files: .webm
        </Typography>
        <Typography variant="body2" className="text-secondaryText">
          • HLS streams: .m3u8
        </Typography>
      </div>
    </div>
  );
};

export default EmbeddableVideoModal;
