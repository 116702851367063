import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, TextField, Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

//Actions
import { updateSectionIngredient } from "../../../../../actions/walkthrough";

//Utility Functions
import { convertToTitleCase } from "../../../../../utils/recipe";

const EditIngredientInfo = ({ section, sectionIndex, ingredient, index, updateSectionIngredient }) => {
  const [unit, setUnit] = useState(ingredient.unit.display.text);
  const [name, setName] = useState(ingredient.name);
  const [state, setState] = useState(ingredient.state);
  const [note, setNote] = useState(ingredient.note);

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSave = () => {
    const newIngredient = {
      ...ingredient,
      unit: {
        ...ingredient.unit,
        display: {
          ...ingredient.unit.display,
          text: unit,
          // Preserve other display properties
          id: ingredient.unit.display.id,
          type: ingredient.unit.display.type,
          magnitude: ingredient.unit.display.magnitude,
        },
        anchor: {
          ...ingredient.unit.anchor,
        },
      },
      id: ingredient.name === name ? ingredient.id : null,
      name,
      state,
      note,
    };
    updateSectionIngredient(sectionIndex, index, newIngredient);
  };

  return (
    <div className="w-full p-4 pt-0">
      <div className="mb-4">
        <Typography variant="h6" className="font-normal">
          Edit Ingredient
        </Typography>
      </div>
      <TextField label="Unit" value={unit} onChange={handleUnitChange} variant="outlined" fullWidth className="mb-4" />
      <TextField label="Name" value={name} onChange={handleNameChange} variant="outlined" fullWidth className="mb-4" />
      <TextField
        label="State"
        value={state}
        onChange={handleStateChange}
        variant="outlined"
        fullWidth
        className="mb-4"
      />
      <TextField label="Note" value={note} onChange={handleNoteChange} variant="outlined" fullWidth className="mb-4" />
      <Button variant="contained" color="primary" onClick={handleSave} fullWidth disableElevation>
        Save
      </Button>
    </div>
  );
};

EditIngredientInfo.propTypes = {
  ingredient: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  updateSectionIngredient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps, { updateSectionIngredient })(EditIngredientInfo);
