import React, { useEffect, useState } from "react";

import ForYouGrid from "./ForYouGrid";
import Spinner from "../../../layout/Spinner";

//API
import api from "../../../../api/api";
const ForYou = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [posts, setPosts] = useState(null);
  const [offset, setOffset] = useState(0);

  const fetchPosts = async () => {
    try {
      const res = await api.get(`/search/foryou/${offset}`);
      setPosts(res.data);
      setError(false);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      setPosts(null);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      {loading ? (
        <div className="pt-[5.5rem]">
          <Spinner />
        </div>
      ) : error ? (
        <div>Error</div>
      ) : (
        <div className="pt-[5.5rem]">
          <ForYouGrid posts={posts} columns={2} />
        </div>
      )}
    </>
  );
};

export default ForYou;
