import api from "../api/api";

//Redux types
import {
  SET_SIGNUP_STAGE,
  SET_REGISTER_ERRORS,
  CLEAR_SIGNUP,
  SIGNUP_PREFERENCES_ERROR,
  SIGNUP_PREFERENCES_NEXT,
  SIGNUP_RESTRICTIONS_ERROR,
  SIGNUP_RESTRICTIONS_NEXT,
  SIGNUP_ABILITY_ERROR,
  SIGNUP_ABILITY_NEXT,
  NAVIGATE,
} from "./types";

//Signup Stages
import { SIGNUP_STAGES } from "../components/auth/register/config/RegisterConfig";

//Actions
import { navigateTo } from "./navigation";

//Set Signup Stage
export const setSignupStage = (value) => (dispatch) => {
  if (value === null) {
    dispatch({
      type: CLEAR_SIGNUP,
    });
  } else {
    dispatch({
      type: SET_SIGNUP_STAGE,
      payload: value,
    });
  }
};

//Set Register Errors
export const setRegisterErrors = (stage, errors) => (dispatch) => {
  dispatch({
    type: SET_REGISTER_ERRORS,
    payload: {
      stage,
      errors,
    },
  });
};

export const oauthInfoNext = (data) => async (dispatch) => {
  try {
    //TODO: Verify data
    const { username, name, dob } = data;

    if (username.length > 20) {
      dispatch(
        setRegisterErrors(SIGNUP_STAGES.OAUTH_INFO, {
          username: { isValid: false, error: "Username must be less than 20 characters" },
        })
      );
    }

    if (name.length > 100) {
      dispatch(
        setRegisterErrors(SIGNUP_STAGES.OAUTH_INFO, {
          name: { isValid: false, error: "Name must be less than 100 characters" },
        })
      );
    }

    const res = await api.post(
      "/users/signup",
      { username, name, dob, stage: SIGNUP_STAGES.OAUTH_INFO },
      { validateStatus: (status) => status >= 200 && status < 401 }
    );

    if (res.status === 400) {
      dispatch(setRegisterErrors(SIGNUP_STAGES.OAUTH_INFO, res.data));
      return;
    }
    //Navigate to next stage
    if (res.data.next) {
      dispatch(setSignupStage(res.data.next));
      dispatch(navigateTo(`/welcome/${res.data.next}`));
    }
  } catch (error) {
    console.log(error);
  }
};

//Dietary Restrictions
export const restrictionsNext = (data) => async (dispatch) => {
  try {
    //Verify data
    let { allergens, diets, dislikes } = data;

    if (allergens.length > 30) {
      dispatch(setRegisterErrors("restrictions", { allergens: { isValid: false, error: "Limit of 30 allergens" } }));
    }

    if (diets.length > 30) {
      dispatch(setRegisterErrors("restrictions", { diets: { isValid: false, error: "Limit of 30 special diets" } }));
    }

    let reqData = { allergens: [], diets: [], dislikes: [], stage: SIGNUP_STAGES.RESTRICTIONS };

    for (let i = 0; i < allergens.length; i++) {
      if (allergens[i].name.length > 128) {
        allergens[i].name = allergens[i].name.substring(0, 128);
      }

      reqData.allergens[i] = allergens[i].name;
    }

    for (let i = 0; i < diets.length; i++) {
      if (diets[i].name.length > 128) {
        diets[i].name = diets[i].name.substring(0, 128);
      }

      reqData.diets[i] = diets[i].name;
    }

    for (let i = 0; i < dislikes.length; i++) {
      if (!dislikes[i].name) {
        console.log(dislikes);
      }
      if (dislikes[i].name.length > 128) {
        dislikes[i].name = dislikes[i].name.substring(0, 128);
      }

      reqData.dislikes[i] = dislikes[i].name;
    }

    const res = await api.post("/users/signup", reqData);

    dispatch({
      type: SIGNUP_RESTRICTIONS_NEXT,
      payload: {
        allergens: reqData.allergens,
        diets: reqData.diets,
        dislikes: reqData.dislikes,
      },
    });

    //Navigate to next stage
    if (res.data.next) {
      dispatch(setSignupStage(res.data.next));
      dispatch(navigateTo(`/welcome/${res.data.next}`));
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: SIGNUP_RESTRICTIONS_ERROR,
    });
    dispatch(
      setRegisterErrors("restrictions", {
        general: { isValid: false, error: "An error occurred. Please refresh the page and try again." },
      })
    );
  }
};
//Cooking Ability
export const abilityNext = (data) => async (dispatch) => {
  try {
    //Verify data
    let { ability } = data;

    if (ability === -1) {
      dispatch(setRegisterErrors("ability", { general: { isValid: false, error: "Must select one" } }));
    }

    let reqData = { ability, stage: SIGNUP_STAGES.ABILITY };

    const res = await api.post("/users/signup", reqData);

    dispatch({
      type: SIGNUP_ABILITY_NEXT,
      payload: {
        ability: reqData.ability,
      },
    });

    //Navigate to next stage
    if (res.data.next) {
      dispatch(setSignupStage(res.data.next));
      dispatch(navigateTo(`/welcome/${res.data.next}`));
    }
  } catch (error) {
    console.log(error);

    dispatch({
      type: SIGNUP_ABILITY_ERROR,
    });
    dispatch(
      setRegisterErrors("ability", {
        general: { isValid: false, error: "An error occurred. Please refresh the page and try again." },
      })
    );
  }
};

//Dietary Preferences
export const preferencesNext = (data) => async (dispatch) => {
  try {
    //Verify data
    let { notifications } = data;

    const res = await api.post("/users/signup", { notifications, stage: SIGNUP_STAGES.PREFERENCES });

    dispatch({
      type: SIGNUP_PREFERENCES_NEXT,
      payload: {
        notifications,
      },
    });
    //Navigate to home, signup complete
    dispatch(setSignupStage(null));
    dispatch(navigateTo(`/`));
  } catch (error) {
    console.log(error);

    dispatch({
      type: SIGNUP_PREFERENCES_ERROR,
    });
    dispatch(
      setRegisterErrors("preferences", {
        general: { isValid: false, error: "An error occurred. Please refresh the page and try again." },
      })
    );
  }
};
