import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { Typography, Divider } from "@mui/material";
import RecipeCard from "../recipes/card/RecipeCard";
import Spinner from "../layout/Spinner";

import api from "../../api/api";

const TrendingRecipes = ({ auth: { isAuthenticated, loading } }) => {
  const [trendingRecipes, setTrendingRecipes] = useState([]);
  const [trendingLoading, setTrendingLoading] = useState(true);
  const [trendingError, setTrendingError] = useState(false);

  const showSignupModal = () => {
    // Implement the logic to show the signup modal
    console.log("Show signup modal");
  };

  const fetchTrendingRecipes = async () => {
    try {
      const res = await api.get("/trending/recipes");
      setTrendingRecipes(res.data);
      setTrendingLoading(false);
    } catch (error) {
      console.error(error);
      setTrendingError(true);
      setTrendingLoading(false);
    }
  };

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      fetchTrendingRecipes();
    }
  }, [loading, isAuthenticated]);

  //Show signup modal after scrolling 2x the window height
  //TODO: Custom signup modal CTA? -> "Get all these recipes and more for free!"
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 2) {
        // Trigger the signup modal
        // Assuming you have a function to show the signup modal
        showSignupModal();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Redirect authenticated users to cookbook trending
  if (isAuthenticated) {
    return <Navigate to="/cookbook/trending" replace />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="text-center mb-8">
        <Typography variant="h4" component="h1" className="mb-4">
          Trending Recipes
        </Typography>
        <Typography variant="body1" color="textSecondary" className="max-w-2xl mx-auto">
          Discover our most popular recipes based on cooking frequency, ratings, and community engagement.
        </Typography>
      </div>

      <Divider className="mb-8" />

      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {trendingRecipes?.map((recipe) => (
          <div key={recipe._id} className="flex flex-col">
            <RecipeCard recipeid={recipe._id} errorType="deleted" />
          </div>
        ))}
      </div>
    </div>
  );
};

TrendingRecipes.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(TrendingRecipes);
