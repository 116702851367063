import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import api from "../../api/api";
import { useNavigate } from "react-router-dom";

//Actions
import { setAlert } from "../../actions/alert";
import { createPost } from "../../actions/feed";
import { imageUploadReset } from "../../actions/media";

//Image Handling
import ImageDropzone from "./components/images/ImageDropzone";
import ImageReorderPreview from "./components/images/ImageReorderPreview";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InfoCircle } from "../../assets/icons/svg/question-circle.svg";

//MUI
import { Typography, IconButton, Button, TextField, CircularProgress } from "@mui/material";

//UI
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import VideoPlayer from "../ui/VideoPlayer";
import CustomModal from "../modal/CustomModal";
import EmbeddableVideoModal from "./EmbeddableVideoModal";
//TO DO: Post flow, api hit, clear state, redirect to feed

const CreatePost = ({ createPost, imageUploadReset }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [postText, setPostText] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const [showInfoModal, setShowInfoModal] = useState(false);

  const [mediaType, setMediaType] = useState("image");

  const handleTextChange = (e) => {
    if (e.target.value.length <= 300) {
      setPostText(e.target.value);
    }
  };

  const handleVideoUrlChange = (e) => {
    setVideoUrl(e.target.value);
  };

  const handlePostSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(false);

      const res = await createPost({ postText, videoUrl });

      console.log(res);

      if (res) {
        //Reset image state
        imageUploadReset();

        //Redirect to feed
        navigate("/");
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center md:pb-[5rem] mt-[4rem] md:px-4 md:py-4 md:ml-[5rem] md:mt-0 min-h-screen bg-neutral-50">
      <CustomModal open={showInfoModal} handleClose={() => setShowInfoModal(false)} type="bottom">
        <EmbeddableVideoModal />
      </CustomModal>
      <form onSubmit={handlePostSubmit} className="w-full max-w-[800px] flex flex-col mt-4 px-2 sm:px-4 md:m-0 md:px-0">
        <Tabs value={mediaType} onValueChange={(value) => setMediaType(value)} className="w-full mb-4">
          <TabsList className="w-full border border-separator p-0">
            <TabsTrigger value="image" className="w-full h-full" disabled={videoUrl !== ""}>
              Upload Images
            </TabsTrigger>
            <TabsTrigger value="video" className="w-full h-full">
              Embed a Video
            </TabsTrigger>
          </TabsList>
        </Tabs>
        {mediaType === "image" ? (
          <div>
            <div>
              <ImageDropzone borderColor="secondaryText-200" />
            </div>
            <div className="my-2">
              <ImageReorderPreview />
            </div>
          </div>
        ) : (
          <div className="w-full">
            {videoUrl && <VideoPlayer url={videoUrl} />}
            <div>
              <TextField
                label="Embed a video URL"
                variant="outlined"
                fullWidth
                className="[&>.MuiInputBase-root]:bg-background mb-4"
                onChange={handleVideoUrlChange}
                value={videoUrl}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowInfoModal(true)}>
                      <InfoCircle className="w-5 h-5 fill-secondaryText-600" />
                    </IconButton>
                  ),
                }}
              />
            </div>
          </div>
        )}
        <TextField
          label="Add a caption"
          multiline
          minRows={4}
          variant="outlined"
          value={postText}
          onChange={handleTextChange}
          fullWidth
          helperText={`${postText.length}/300`}
          className="[&>.MuiInputBase-root]:bg-background mb-4"
        />
        <Button type="submit" variant="contained" color="primary" disableElevation disabled={loading} fullWidth>
          + Create Post
        </Button>
      </form>
    </div>
  );
};

CreatePost.propTypes = {
  createPost: PropTypes.func.isRequired,
  imageUploadReset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { createPost, imageUploadReset })(CreatePost);
