import React from "react";

import VideoPlayer from "../../ui/VideoPlayer";
import PostTextContent from "./components/PostTextContent";
const MediaContent = ({ post }) => {
  return (
    <>
      <div className="w-full">
        <VideoPlayer url={post.video} />
      </div>
      {post.text && post.text !== "" && (
        <PostTextContent text={post.text} username={post.user.username} hasImg={post.data.length > 0} />
      )}
    </>
  );
};

export default MediaContent;
