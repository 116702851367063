import React, { useState, useEffect } from "react";

import { Typography, Button } from "@mui/material";

//Modal
import SectionMacrosModal from "../modals/SectionMacrosModal";
import CustomModal from "../../../../modal/CustomModal";

//Utils
import {
  decimalToFraction,
  convertToTitleCase,
  formatAmountUnit,
  formatIngredient,
  formatState,
} from "../../../../../utils/recipe";

const SectionYield = ({ title, yieldObj }) => {
  return (
    <div className="px-4 pt-0 pb-6 md:px-8">
      <div>
        <Typography variant="subtitle2" className="font-normal leading-snug text-secondaryText ">
          {title ? title : "Section"} Yield:
        </Typography>
      </div>
      <div className="flex flex-grow-0 gap-1">
        {yieldObj.amount && (
          <Typography variant="subtitle2" className="font-normal">{`${yieldObj.amount} `}</Typography>
        )}
        {yieldObj.unit && yieldObj.unit.shortName && (
          <Typography variant="subtitle2" className="font-normal">{`${yieldObj.unit.shortName} `}</Typography>
        )}
        {yieldObj.ingredient && yieldObj.ingredient.name && (
          <Typography variant="subtitle2" className="font-medium">
            {formatIngredient(yieldObj.ingredient)}
          </Typography>
        )}
      </div>
    </div>
  );
};

const RecipeIngredientsSection = ({ section }) => {
  const [showMacrosModal, setShowMacrosModal] = useState(false);

  const handleMacrosClose = () => {
    setShowMacrosModal(false);
  };

  return (
    <div>
      <div className="w-full ">
        <div className="flex flex-row items-center bg-neutral-100 py-4 px-4 md:px-8">
          <Typography variant="h6" className="font-sans font-normal text-primaryText leading-none">
            {section.title}
          </Typography>
        </div>
        <CustomModal open={showMacrosModal} handleClose={handleMacrosClose} type="bottom">
          <SectionMacrosModal macros={section.macros} servingSize={section.servingSize} title={section.title} />
        </CustomModal>
        {section.servings && (
          <div className="px-4 md:px-8">
            <div className="pt-2">
              <div className="flex flex-row justify-between">
                <div className="w-1/2">
                  <Typography
                    variant="subtitle2"
                    className="font-normal leading-snug"
                  >{`${section.servings} servings`}</Typography>
                  {section.servingSize && section.servingSize !== "" && (
                    <Typography
                      variant="subtitle2"
                      className="text-secondaryText-600 italic text-xs font-normal"
                    >{`Serving Size: ${section.servingSize}`}</Typography>
                  )}
                </div>
                <div className="flex flex-col justify-between items-end w-1/2">
                  {section.macros &&
                  section.macros.length > 0 &&
                  section.macros[0] &&
                  section.macros[0].name === "Calories" ? (
                    <Typography variant="subtitle2" className="font-normal leading-snug text-xs text-secondaryText-600">
                      <span className="font-medium">{section.macros[0].amount}</span> kcal/serving
                    </Typography>
                  ) : (
                    <div></div>
                  )}
                  {section.macros && section.macros.length > 0 && (
                    <Button className="p-0 hover:bg-transparent" disableRipple onClick={() => setShowMacrosModal(true)}>
                      <Typography variant="subtitle2" className="text-mainGreen leading-snug text-xs font-normal">
                        View Macros
                      </Typography>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ul className="ml-4 px-4 md:px-8 py-4">
        {section.ingredients.map((ingredient, j) => {
          return (
            <li className="my-3 marker:text-mainGreen">
              <div className="flex flex-col">
                <Typography variant="body1" className="font-medium">
                  <span className="font-normal">{formatAmountUnit(ingredient)}</span>
                  {formatIngredient(ingredient)}
                  {ingredient.state !== "" && (
                    <span className="font-normal italic text-secondaryText text-sm">{formatState(ingredient)}</span>
                  )}
                </Typography>
                {ingredient.note && ingredient.note !== "" && (
                  <Typography variant="body2" className="text-secondaryText">
                    ({ingredient.note})
                  </Typography>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {section.yield && <SectionYield title={section.title} yieldObj={section.yield} />}
    </div>
  );
};

export default RecipeIngredientsSection;
