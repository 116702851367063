import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import api from "../../../api/api";

//DOM imports
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../ui/dropdown-menu";
import { Button as ShadcnButton } from "../../ui/button";
import { ChevronDown } from "lucide-react";

import Spinner from "../../layout/Spinner";

//MUI
import { Button, IconButton, Typography, Divider, TextField, Chip } from "@mui/material";
import { Badge } from "../../ui/badge";

//Default PFP
import DefaultPFP from "../../../assets/default/profile_picture.svg";

//Icons
import { ReactComponent as ChevronLeft } from "../../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as GoogleIcon } from "../../auth/res/google.svg";
import { ReactComponent as AppleIcon } from "../../../assets/icons/svg/apple-brands-solid.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ChevronRight } from "../../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as TitleIcon } from "../../../assets/icons/svg/user-delete.svg";
import profile from "../../../reducers/profile";

//Actions
import { deleteAccount } from "../../../actions/auth";
import { setAlert } from "../../../actions/alert";

//Capacitor
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

const Delete = ({ profile: { currentProfile }, setAlert, deleteAccount }) => {
  const location = useLocation();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [oAuthToken, setOAuthToken] = useState("");

  const [deleteReason, setDeleteReason] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [accountType, setAccountType] = useState(null);
  const [accountTypeLoading, setAccountTypeLoading] = useState(true);
  const [accountTypeError, setAccountTypeError] = useState(false);

  const [capacitorPlatform, setCapacitorPlatform] = useState(null);

  const getCapacitorPlatform = () => {
    const platform = Capacitor.getPlatform();

    console.log(platform);

    if (platform) {
      console.log(platform);
      setCapacitorPlatform(platform);
    } else {
      console.log("No platform");
      setCapacitorPlatform("web");
    }
  };
  useEffect(() => {
    getCapacitorPlatform();
  }, []);

  const handleGoogleClick = async () => {
    if (capacitorPlatform === "web") {
      await Browser.open({ url: "https://www.thispantry.com/api/auth/google/delete" });
    } else if (capacitorPlatform === "ios") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/google/delete/ios",
      });
    } else if (capacitorPlatform === "android") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/google/delete",
        presentationStyle: "popover",
        windowName: "_self",
        toolbarColor: "#598a56",
      });
    }
  };

  const handleAppleClick = async () => {
    if (capacitorPlatform === "web") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/apple/delete",
      });
    } else if (capacitorPlatform === "ios") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/apple/delete/ios",
      });
    } else if (capacitorPlatform === "android") {
      await Browser.open({
        url: "https://www.thispantry.com/api/auth/apple/delete",
        presentationStyle: "popover",
        windowName: "_self",
        toolbarColor: "#598a56",
      });
    }
  };

  const getAccountType = async () => {
    try {
      const res = await api.get("/auth/type");
      setAccountType(res.data.type);
      setAccountTypeLoading(false);
      setAccountTypeError(false);
    } catch (error) {
      setAccountTypeError(true);
      setAccountTypeLoading(false);
    }
  };
  useEffect(() => {
    getAccountType();
  }, []);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    if (token) {
      setOAuthToken(token);
    }
  }, [location]);

  const handleDeleteReasonChange = (value) => {
    setDeleteReason(value);
  };

  const handleAdditionalInfoChange = (e) => {
    if (e.target.value?.length > 4096) {
      return;
    }
    setAdditionalInfo(e.target.value);
  };

  const handlePassword1Change = (value) => {
    if (value?.length > 100) {
      return;
    }
    setPassword1(value);
  };

  const handlePassword2Change = (value) => {
    if (value?.length > 100) {
      return;
    }
    setPassword2(value);
  };

  const handleDeleteAccount = async () => {
    setDeleteLoading(true);

    if (accountType === "local") {
      if (password1 !== password2) {
        setAlert("Passwords do not match", "warning");
        return;
      }

      if (password1 === "" || password2 === "") {
        setAlert("Please enter your password", "warning");
        return;
      }
      await deleteAccount({ password: password1, reason: deleteReason, info: additionalInfo });
    } else {
      await deleteAccount({ token: oAuthToken, reason: deleteReason, info: additionalInfo });
    }
    setOAuthToken("");
    setDeleteLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Delete Account</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:pt-6 bg-neutral-50 min-h-screen">
        <div className="w-full md:pl-[5rem]">
          <div className="px-4 py-3 md:px-8 md:py-6 md:mx-auto w-full md:max-w-[600px] border-b md:border border-separator md:rounded-[16px] bg-background">
            <div className="flex flex-col">
              <div className="my-4 md:mt-0">
                <Link className="hover:bg-transparent text-secondaryText p-0 justify-start" to="/settings">
                  <Typography variant="body2" className="body1 leading-none">{`< Go Back`}</Typography>
                </Link>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                  <div className="mr-4">
                    <TitleIcon className="h-[1.25rem] fill-primaryText"></TitleIcon>
                  </div>
                  <div className="mb-4">
                    <Typography variant="h6" className="font-normal leading-none mb-1">
                      Delete Account
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center gap-4 my-8">
                <Link to={`/${currentProfile.username}`}>
                  <img
                    src={
                      currentProfile && currentProfile.picture && currentProfile.picture !== ""
                        ? currentProfile.picture
                        : DefaultPFP
                    }
                    alt="Your profile"
                    className="h-[4rem] w-[4rem] object-cover rounded-[50%] "
                  />
                </Link>
                <Link to={`/${currentProfile.username}`}>
                  <Typography variant="subtitle1" className="font-normal">
                    {currentProfile && currentProfile.name}
                  </Typography>
                  <Typography variant="subtitle1" className="text-mainGreen leading-tight font-medium">
                    {currentProfile && currentProfile.username && `@${currentProfile.username}`}
                  </Typography>
                </Link>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="pb-8 border-b border-separator">
                <Typography variant="h6" className="leading-snug  mb-2 text-mainGreen font-medium">
                  We're sorry to see you go.
                </Typography>
                <Typography variant="body2" className="leading-snug text-primaryText font-normal ">
                  Are you sure you want to delete your account? Once you confirm, all of your data will be lost.
                </Typography>
              </div>
              {accountTypeLoading ? (
                <Spinner />
              ) : accountTypeError ? (
                <Typography variant="body2" className="leading-snug text-primaryText font-normal ">
                  An error occurred while fetching your account type. Please refresh the page and try again.
                </Typography>
              ) : (
                <div className="flex flex-col gap-4 py-4">
                  {accountType === "local" && (
                    <>
                      <TextField
                        value={password1}
                        onChange={(e) => handlePassword1Change(e.target.value)}
                        type="password"
                        fullWidth
                        label="Current Password"
                      />
                      <TextField
                        value={password2}
                        onChange={(e) => handlePassword2Change(e.target.value)}
                        type="password"
                        fullWidth
                        label="Verify Password"
                      />
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <ShadcnButton
                            variant="outline"
                            className="w-full justify-between font-normal text-secondaryText-600/90 rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md"
                          >
                            {deleteReason || "Select a reason"}
                            <ChevronDown className="w-3 opacity-50" />
                          </ShadcnButton>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px] z-[1301]">
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("No longer using the app")}>
                            No longer using the app
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Privacy concerns")}>
                            Privacy concerns
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Found a better alternative")}>
                            Found a better alternative
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Difficulty using the app")}>
                            Difficulty using the app
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Not enough features")}>
                            Not enough features
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Too many notifications")}>
                            Too many notifications
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Content quality issues")}>
                            Content quality issues
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Technical problems")}>
                            Technical problems
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Creating a new account")}>
                            Creating a new account
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Prefer not to say")}>
                            Prefer not to say
                          </DropdownMenuItem>
                          <DropdownMenuItem onSelect={() => handleDeleteReasonChange("Other")}>Other</DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                      {deleteReason && deleteReason !== "Prefer not to say" && (
                        <TextField
                          value={additionalInfo}
                          onChange={(e) => handleAdditionalInfoChange(e)}
                          multiline
                          rows={3}
                          fullWidth
                          label="Additional Information"
                          helperText="Please provide any additional details about your reason for deleting your account. This will help us improve thisPantry for future users."
                        />
                      )}
                      <div className="text-center">
                        <Typography variant="caption" className="font-normal text-secondaryText">
                          Confirm your password to delete your account.<br></br>Deleting your account is irreversible,
                          and all of your data will be lost.
                        </Typography>
                      </div>
                    </>
                  )}
                  {accountType === "apple" &&
                    (oAuthToken && oAuthToken !== "" && oAuthToken ? (
                      <Button
                        variant="outlined"
                        fullWidth
                        startIcon={<AppleIcon className="h-[1.5rem]" />}
                        classes={{
                          root: "relative pl-[1.5rem] border-secondaryText-300 hover:border-secondaryText",
                          startIcon: "absolute left-[1rem]",
                        }}
                        disabled={true}
                      >
                        Log in with Apple
                      </Button>
                    ) : (
                      <div>
                        {capacitorPlatform === "web" ? (
                          <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<AppleIcon className="h-[1.5rem]" />}
                            classes={{
                              root: "relative pl-[1.5rem] border-secondaryText-300 hover:border-secondaryText",
                              startIcon: "absolute left-[1rem]",
                            }}
                            component={Link}
                            to={"/api/auth/apple/delete"}
                            target="_blank"
                            disabled={deleteLoading}
                          >
                            Log in with Apple
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<AppleIcon className="h-[1.5rem]" />}
                            classes={{
                              root: "relative pl-[1.5rem] border-secondaryText-300 hover:border-secondaryText",
                              startIcon: "absolute left-[1rem]",
                            }}
                            onClick={handleAppleClick}
                            disabled={deleteLoading}
                          >
                            Log in with Apple
                          </Button>
                        )}

                        <div className="text-center mt-4">
                          <Typography variant="caption" className="font-normal text-secondaryText">
                            Login to your Apple Account to delete your account.<br></br>Deleting your account is
                            irreversible, and all of your data will be lost.
                          </Typography>
                        </div>
                      </div>
                    ))}

                  {accountType === "google" &&
                    (oAuthToken && oAuthToken !== "" && oAuthToken ? (
                      <Button
                        variant="outlined"
                        fullWidth
                        startIcon={<GoogleIcon className="h-[1.5rem]" />}
                        classes={{
                          root: "relative pl-[1.5rem] border-secondaryText-300 hover:border-secondaryText",
                          startIcon: "absolute left-[1rem]",
                        }}
                        onClick={handleGoogleClick}
                        disabled={true}
                      >
                        Log in with Google
                      </Button>
                    ) : (
                      <div>
                        {capacitorPlatform === "web" ? (
                          <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<GoogleIcon className="h-[1.5rem]" />}
                            classes={{
                              root: "relative pl-[1.5rem] border-secondaryText-300 hover:border-secondaryText",
                              startIcon: "absolute left-[1rem]",
                            }}
                            component={Link}
                            to={"/api/auth/google/delete"}
                            target="_blank"
                            disabled={deleteLoading}
                          >
                            Log in with Google
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<GoogleIcon className="h-[1.5rem]" />}
                            classes={{
                              root: "relative pl-[1.5rem] border-secondaryText-300 hover:border-secondaryText",
                              startIcon: "absolute left-[1rem]",
                            }}
                            onClick={handleGoogleClick}
                            disabled={deleteLoading}
                          >
                            Log in with Google
                          </Button>
                        )}

                        <div className="text-center mt-4">
                          <Typography variant="caption" className="font-normal text-secondaryText">
                            Login to your Google Account to delete your account.<br></br>Deleting your account is
                            irreversible, and all of your data will be lost.
                          </Typography>
                        </div>
                      </div>
                    ))}

                  <Button
                    variant="contained"
                    color="danger"
                    disableElevation
                    onClick={handleDeleteAccount}
                    disabled={deleteLoading || (accountType !== "local" && !oAuthToken)}
                  >
                    Delete Account
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Delete.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, deleteAccount })(Delete);
