import React from "react";

import { styled } from "@mui/material/styles";

import { Link } from "react-router-dom";

//Carousel

import ImageCarousel from "../../../feed/types/components/ImageCarousel";
//Utils
import { shortInteractionCount } from "../../../../utils/post";

//Icons
import { ReactComponent as HeartIcon } from "../../../../assets/icons/svg/heart.svg";
import { ReactComponent as CommentIcon } from "../../../../assets/icons/svg/comment.svg";

import { Typography } from "@mui/material";

const StandardGridMedia = ({ post }) => {
  return (
    <div className="w-full aspect-square min-[532px]:border min-[532px]:border-separator min-[532px]:rounded-lg min-[532px]:shadow-sm bg-background">
      <Link to={`/${post.user.username}/post/${post.id}`}>
        <div className="relative">
          <div className="w-full aspect-square">
            <ImageCarousel
              data={post.data}
              rootStyles={"rounded-lg"}
              dotsTop={true}
              dotsAlign="left"
              showEmpty={true}
            />
          </div>
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black/60 to-white/30 min-[532px]:rounded-lg z-[2] pointer-events-none"></div>
          <div className="absolute bottom-0 w-full p-4 box-border z-10">
            <Typography variant="subtitle1" className="text-white">{`@${post.user.username}`}</Typography>
            <div className="flex flex-row align-center">
              <div className="flex flex-row align-center items-center">
                <HeartIcon className="h-[1rem] fill-white" />
                <Typography className="text-white ml-2">
                  {shortInteractionCount(post.interactions.likesCount)}
                </Typography>
              </div>
              <div className="flex flex-row align-center items-center ml-4">
                <CommentIcon className="h-[1rem] fill-white" />
                <Typography className="text-white ml-2">
                  {shortInteractionCount(post.interactions.commentsCount)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default StandardGridMedia;
