import React from "react";

//Components
import StandardPostGridManager from "./StandardPostGridManager";

const StandardPostGrid = ({ posts }) => {
  return (
    <div className="grid min-[532px]:gap-2 md:gap-4 min-[532px]:mt-4 auto-fill-minmax-300">
      {posts.map((post) => (
        <StandardPostGridManager key={post.id} post={post} />
      ))}
    </div>
  );
};

export default StandardPostGrid;
