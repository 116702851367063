import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api/api";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../layout/Spinner";
import ProfileSearchObject from "../search/sections/profiles/ProfileSearchObject";
import { Typography, Button } from "@mui/material";
import { ReactComponent as FollowIcon } from "../../assets/icons/svg/user-plus-solid.svg";

const FollowSuggestions = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [initialFetch, setInitialFetch] = useState(true);

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const fetchSuggestions = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/profile/suggested/${offset}`);

      if (initialFetch && response.data.profiles.length === 0) {
        setHasMore(false);
        setLoading(false);
        setInitialFetch(false);
        return;
      }

      setSuggestions((prev) => [...prev, ...response.data.profiles]);
      setHasMore(response.data.hasMore);
      setOffset((prev) => prev + response.data.profiles.length);
      setLoading(false);
      setInitialFetch(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  if (error) return null;

  // Easter egg message when no suggestions available
  if (!loading && suggestions.length === 0) {
    if (!navigator.share) {
      return null;
    }
    return (
      <div className="w-full my-8 md:my-0 md:border md:border-separator md:rounded-lg p-4 md:shadow-sm bg-background">
        <div className="flex flex-col justify-between aspect-[3/2]">
          <div className="flex flex-row items-center">
            <FollowIcon className="h-4 fill-secondaryText mr-2" />
            <Typography variant="subtitle1">People to Follow</Typography>
          </div>
          <div>
            <Typography variant="h5" className="mb-2">
              🎉 You're a Social Butterfly!
            </Typography>
            <Typography variant="body1" className="text-secondaryText">
              Wait what?<br></br>You've connected with everyone in the thisPantry community. Time to invite some foodie
              friends!
            </Typography>
            {navigator.share && (
              <Button
                variant="contained"
                color="primary"
                className="mt-4"
                fullWidth
                onClick={() => {
                  navigator
                    .share({
                      title: "Join me on thisPantry",
                      text: "Bring all your recipes together with thisPantry. Generate new recipes, manage your pantry, and gain valuable insights on expenses and nutrition. Simplify your cooking and make every ingredient count with thisPantry.",
                      url: "https://www.thispantry.com",
                    })
                    .catch((error) => console.log("Error sharing", error));
                }}
              >
                + Invite Friends
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full my-8 md:my-0 md:border md:border-separator md:rounded-lg p-4 md:shadow-sm bg-background">
      <div className="flex flex-row items-center justify-between mb-6">
        <div className="flex flex-row items-center">
          <FollowIcon className="h-4 fill-secondaryText mr-2" />
          <Typography variant="subtitle1">People to Follow</Typography>
        </div>

        {navigator.share && (
          <Button
            disableRipple
            className="hover:bg-transparent"
            onClick={() => {
              navigator
                .share({
                  title: "Join me on thisPantry",
                  text: "Bring all your recipes together with thisPantry. Generate new recipes, manage your pantry, and gain valuable insights on expenses and nutrition. Simplify your cooking and make every ingredient count with thisPantry.",
                  url: "https://www.thispantry.com",
                })
                .catch((error) => console.log("Error sharing", error));
            }}
          >
            <Typography variant="subtitle2" className="font-normal text-primaryText leading-none">
              <span className="text-mainGreen font-medium">+</span> Invite Friends
            </Typography>
          </Button>
        )}
      </div>

      <div className="overflow-x-auto">
        <InfiniteScroll
          dataLength={suggestions.length}
          next={fetchSuggestions}
          hasMore={hasMore}
          loader={<Spinner />}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.9}
          className="flex"
          style={{ overflow: "visible" }}
        >
          <div className="grid grid-flow-col auto-cols-[minmax(300px,1fr)] gap-4">
            {suggestions.map((user) => (
              <div key={user._id} className="pb-4">
                <ProfileSearchObject profile={user} border={false} padding="p-0" />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

FollowSuggestions.propTypes = {};

export default FollowSuggestions;
