import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//API
import api from "../../../../../api/api";

//Components
import Spinner from "../../../../layout/Spinner";
import FinishRecipeSection from "./FinishRecipeSection";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

//Actions
import { setAlert } from "../../../../../actions/alert";

const FinishRecipeModal = ({ walkthrough: { recipe }, setAlert }) => {
  const navigate = useNavigate();

  const [loadingCookable, setLoadingCookable] = useState(true);
  const [cookableSections, setCookableSections] = useState([]);

  const setCookableChecked = (index, value) => {
    let newSections = [...cookableSections];
    console.log(newSections);
    console.log(index, value);
    console.log(newSections[index].cooked, value);
    newSections[index].cooked = value;
    setCookableSections(newSections);
  };
  const getCookableSections = () => {
    let sections = [];

    for (let [index, section] of recipe.sections.entries()) {
      if (section.ingredients.length > 0) {
        sections.push({
          index: index,
          title: section.title,
          cooked: true,
          showIngredients: false,
        });
      }
    }

    setCookableSections(sections);
    setLoadingCookable(false);
  };

  const handleSubmit = async () => {
    //Send cooked data to DB

    try {
      const res = await api.post("/pantry/cook", { recipeId: recipe._id, sections: cookableSections });

      setAlert(`Cooked ${recipe.name}`, "success");
      //Redirect to cookbook
      navigate("/cookbook");
      //Reset walkthrough state
    } catch (error) {
      setAlert(`Error finishing ${recipe.name}. Please refresh the page and try again`, "error");
    }
  };

  useEffect(() => {
    getCookableSections();
  }, []);

  //TO DO:
  //Display sections with ingredients
  //Handle "used" state
  //Handle "finish" button click

  return (
    <div className="mx-4 mb-4">
      {loadingCookable ? (
        <Spinner />
      ) : (
        <>
          <div className="mb-4 w-full">
            <Typography variant="h6" className="font-normal">
              Which sections did you make?
            </Typography>
          </div>
          <div className="py-4">
            {cookableSections.map((section) => (
              <FinishRecipeSection
                section={recipe.sections[section.index]}
                sectionIndex={section.index}
                checked={cookableSections[section.index].cooked}
                setChecked={setCookableChecked}
              />
            ))}
          </div>
          <div className="w-full flex justify-end mt-4">
            <Button variant="contained" color="primary" disableElevation onClick={() => handleSubmit()} fullWidth>
              Finish Recipe
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

FinishRecipeModal.propTypes = {
  walkthrough: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps, { setAlert })(FinishRecipeModal);
