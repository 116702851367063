import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

//Components
import Ingredient from "../components/Ingredient";

const FinishRecipeSection = ({ section, sectionIndex, checked, setChecked }) => {
  const [showIngredients, setShowIngredients] = useState(false);

  return (
    <div className="flex items-start mb-2">
      <div>
        <Checkbox
          className="p-0"
          checked={checked}
          color="primary"
          size="small"
          onChange={(e) => {
            console.log(sectionIndex);
            console.log(section);
            setChecked(sectionIndex, e.target.checked);
          }}
        />
      </div>
      <div className="ml-2 mt-1 flex flex-col">
        <Typography variant="h6" className="font-normal leading-none">
          {section.title}
        </Typography>
        <div>
          {showIngredients ? (
            <>
              <Button
                disableElevation
                disableRipple
                className="hover:bg-transparent px-0"
                onClick={() => setShowIngredients(false)}
              >
                <Typography variant="subtitle2" className="font-normal text-xs text-secondaryText">
                  Hide Ingredients
                </Typography>
              </Button>
              <ul>
                {section.ingredients.map((ingredient, index) => (
                  <Ingredient
                    key={index}
                    ingredient={ingredient}
                    section={section}
                    sectionIndex={sectionIndex}
                    index={index}
                  />
                ))}
              </ul>
            </>
          ) : (
            <Button
              disableElevation
              disableRipple
              className="hover:bg-transparent px-0"
              onClick={() => setShowIngredients(true)}
            >
              <Typography variant="subtitle2" className="font-normal text-xs text-secondaryText">
                Edit Ingredients
              </Typography>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinishRecipeSection;
