import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { setAlert } from "../../../actions/alert";

//API
import api from "../../../api/api";

//DOM imports
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//MUI
import { Button, IconButton, Typography, Divider } from "@mui/material";

//Icons
import { ReactComponent as ChevronLeft } from "../../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as TitleIcon } from "../../../assets/icons/svg/notifications-bell.svg";

//Shadcn
import { Switch } from "../../ui/switch";
import Spinner from "../../layout/Spinner";

const Notifications = ({}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [settings, setSettings] = useState(null);

  const fetchSettings = async () => {
    try {
      const res = await api.get("/settings/notifications");
      setSettings(res.data);
      console.log(res.data);

      setError(false);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const updateSetting = async (type, setting, value) => {
    let oldSettings;
    let newConfig;
    try {
      setSettings((prevSettings) => {
        oldSettings = prevSettings;
        // Create a deep copy of the previous settings to avoid direct mutation
        const newSettings = JSON.parse(JSON.stringify(prevSettings));

        // Update the specific setting
        newSettings[type][setting] = value;

        // Return the new settings object to update the state
        console.log(newSettings);
        newConfig = newSettings;
        return newSettings;
      });

      // Send the updated settings to the server
      await api.put("/settings/notifications", newConfig);
    } catch (error) {
      if (oldSettings) {
        setSettings(oldSettings);
      }
      setAlert("An error occurred updating your settings. Please refresh the page and try again", "error");
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <>
      <Helmet>
        <title>Notification Settings</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:pt-6 bg-neutral-50 min-h-screen">
        <div className="w-full md:pl-[5rem]">
          <div className="px-4 py-2 md:px-8 md:py-3 md:pt-6 md:mx-auto w-full md:max-w-[600px] border-b md:border border-separator md:rounded-[16px] bg-background">
            {loading ? (
              <Spinner />
            ) : (
              <>
                {error ? (
                  <div className="my-4">
                    <div className="mb-4">
                      <Typography variant="h3" className="text-mainGreen font-bold">
                        Uh oh.
                      </Typography>
                    </div>
                    <Typography variant="h6" className="font-normal leading-tight">
                      An error occurred getting your settings.<br></br>Please refresh the page and try again.
                    </Typography>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col">
                      <div className="my-4 md:mt-0">
                        <Link className="hover:bg-transparent text-secondaryText p-0 justify-start" to="/settings">
                          <Typography variant="body2" className="body1 leading-none">{`< Go Back`}</Typography>
                        </Link>
                      </div>
                      <div className="flex flex-row mt-">
                        <div className="mr-4">
                          <TitleIcon className="h-[1.25rem] fill-primaryText"></TitleIcon>
                        </div>
                        <div className="mb-4">
                          <Typography variant="h6" className="font-normal leading-none mb-1">
                            Notification Settings
                          </Typography>
                          <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                            Manage when you receive notifications
                          </Typography>
                        </div>
                      </div>
                    </div>

                    <Divider />

                    {settings && settings.social && (
                      <div>
                        <div className="flex flex-row justify-between items-center py-3">
                          <div>
                            <Typography variant="subtitle2" className="font-normal">
                              Like
                            </Typography>
                          </div>
                          <div>
                            <Switch
                              checked={settings.social.sendLikeNotifs}
                              onCheckedChange={(newValue) => updateSetting("social", "sendLikeNotifs", newValue)}
                              className="ml-4"
                            />
                          </div>
                        </div>
                        <Divider />

                        <div className="flex flex-row justify-between items-center py-3 mt-1">
                          <div>
                            <Typography variant="subtitle2" className="font-normal">
                              Comment
                            </Typography>
                          </div>
                          <div>
                            <Switch
                              checked={settings.social.sendCommentNotifs}
                              onCheckedChange={(newValue) => updateSetting("social", "sendCommentNotifs", newValue)}
                              className="ml-4"
                            />
                          </div>
                        </div>
                        <Divider />
                        <div className="flex flex-row justify-between items-center py-3 mt-1">
                          <div>
                            <Typography variant="subtitle2" className="font-normal">
                              Pin
                            </Typography>
                          </div>
                          <div>
                            <Switch
                              checked={settings.social.sendPinNotifs}
                              onCheckedChange={(newValue) => updateSetting("social", "sendPinNotifs", newValue)}
                              className="ml-4"
                            />
                          </div>
                        </div>
                        <Divider />
                        <div className="flex flex-row justify-between items-center py-3 mt-1">
                          <div>
                            <Typography variant="subtitle2" className="font-normal">
                              Bookmark
                            </Typography>
                          </div>
                          <div>
                            <Switch
                              checked={settings.social.sendBookmarkNotifs}
                              onCheckedChange={(newValue) => updateSetting("social", "sendBookmarkNotifs", newValue)}
                              className="ml-4"
                            />
                          </div>
                        </div>
                        <Divider />
                        <div className="flex flex-row justify-between items-center py-3 mt-1">
                          <div>
                            <Typography variant="subtitle2" className="font-normal">
                              Follow
                            </Typography>
                          </div>
                          <div>
                            <Switch
                              checked={settings.social.sendFollowNotifs}
                              onCheckedChange={(newValue) => updateSetting("social", "sendFollowNotifs", newValue)}
                              className="ml-4"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Notifications.propTypes = {};

export default connect(null, {})(Notifications);
