import api from "../api/api";

import store, { history } from "../store";

//Redux types
import { TOGGLE_CREATE_MODAL, TOGGLE_KITCHEN_DASHBOARD_MODAL, NAVIGATE } from "./types";

export const toggleCreateModal = (value) => async (dispatch) => {
  dispatch({
    type: TOGGLE_CREATE_MODAL,
    payload: value,
  });
};

export const toggleKitchenDashboardModal = (value) => async (dispatch) => {
  dispatch({
    type: TOGGLE_KITCHEN_DASHBOARD_MODAL,
    payload: value,
  });
};

export const navigateTo = (path) => (dispatch) => {
  dispatch({
    type: NAVIGATE,
    payload: path,
  });
};
