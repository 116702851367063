import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Typography, TextField, Button, IconButton } from "@mui/material";
import ReactPlayer from "react-player";

//Actions
import { setSectionVideo } from "../../../../actions/createrecipe";

//Icons
import { ReactComponent as InfoCircle } from "../../../../assets/icons/svg/question-circle.svg";

import CustomModal from "../../../modal/CustomModal";
import VideoPlayer from "../../../ui/VideoPlayer";
import EmbeddableVideoModal from "../../EmbeddableVideoModal";

const RecipeSectionVideoModal = ({ section, sectionIndex, setSectionVideo, handleClose }) => {
  const [videoUrl, setVideoUrl] = useState(section.video);
  const [helperText, setHelperText] = useState("Add a video URL to embed");
  const [videoError, setVideoError] = useState(false);

  const [showInfoModal, setShowInfoModal] = useState(false);

  const handleVideoUrlChange = (e) => {
    const url = e.target.value;

    if (url === "") {
      setVideoUrl(url);
      setVideoError(false);
      setHelperText("Add a video URL to embed");
      return;
    }

    if (url.length > 256) {
      setVideoError(true);
      setHelperText("URL is too long. Please enter a shorter URL.");
      return;
    }

    if (ReactPlayer.canPlay(url)) {
      setVideoUrl(url);
      setVideoError(false);
      setHelperText("Add a video URL to embed");
    } else {
      setVideoUrl(url);
      setVideoError(true);
      setHelperText("Please enter a valid URL.");
    }
  };

  const handleSubmit = () => {
    setSectionVideo(videoUrl, sectionIndex);
    handleClose();
  };

  return (
    <div className="pb-4">
      <CustomModal open={showInfoModal} handleClose={() => setShowInfoModal(false)} type="bottom">
        <EmbeddableVideoModal />
      </CustomModal>
      <div className="mb-4 px-4">
        <Typography variant="h6" className="font-normal">
          Attach a Video
        </Typography>
      </div>
      {videoUrl && (
        <div>
          <VideoPlayer url={videoUrl} />
        </div>
      )}
      <div className={videoUrl ? "px-4 py-4" : "px-4"}>
        <TextField
          label="Video URL"
          value={videoUrl}
          onChange={handleVideoUrlChange}
          fullWidth
          helperText={helperText}
          error={videoError}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setShowInfoModal(true)}>
                <InfoCircle className="w-5 h-5 fill-secondaryText-600" />
              </IconButton>
            ),
          }}
        />
      </div>
      <div className="px-4 mt-4">
        <Button variant="contained" color="primary" disableElevation onClick={handleSubmit} fullWidth>
          Save
        </Button>
      </div>
    </div>
  );
};

RecipeSectionVideoModal.propTypes = {
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  setSectionVideo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setSectionVideo })(RecipeSectionVideoModal);
