import React, { useEffect, useState } from "react";
import { Typography, Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";

import api from "../../api/api";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../layout/Spinner";
import ProfileSearchObject from "../search/sections/profiles/ProfileSearchObject";

import { ReactComponent as SearchIcon } from "../../assets/icons/svg/search.svg";
import { ReactComponent as InviteIcon } from "../../assets/icons/svg/user-plus-solid.svg";

import { preventWidows } from "../../utils/text";

const EndOfFeed = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const fetchSuggestions = async () => {
    try {
      const response = await api.get(`/profile/suggested/${offset}`);
      setSuggestions([...suggestions, ...response.data.profiles]);
      setHasMore(response.data.hasMore);
      setOffset(offset + 20);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  if (suggestions.length === 0 && !loading) {
    return null;
  }

  return (
    <div className="my-4 p-4 flex flex-col gap-8 w-full md:border md:border-separator md:rounded-lg md:shadow-sm bg-background">
      <div className="flex flex-row w-full justify-between gap-4">
        <div className="">
          <Typography variant="h6" className="font-normal text-primaryText leading-none mb-1">
            You're all caught up!
          </Typography>
          <Typography variant="caption" component="p" className="font-normal text-secondaryText leading-snug">
            {preventWidows("Follow more chefs or invite friends and see their recipes and posts in your feed", 4)}
          </Typography>
        </div>

        <div className="flex flex-col gap-2">
          <Link to="/search?type=profiles">
            <Button
              variant="outlined"
              className="border border-secondaryText-200 hover:border-secondaryText"
              size="small"
              disableRipple
            >
              <SearchIcon className="h-3 mr-2 fill-secondaryText" />
              <Typography variant="caption" component="p">
                Search&nbsp;Profiles
              </Typography>
            </Button>
          </Link>

          {navigator.share && (
            <Button
              size="small"
              disableRipple
              className="hover:bg-transparent"
              onClick={() => {
                navigator
                  .share({
                    title: "Join me on thisPantry",
                    text: "Bring all your recipes together with thisPantry. Generate new recipes, manage your pantry, and gain valuable insights on expenses and nutrition. Simplify your cooking and make every ingredient count with thisPantry.",
                    url: "https://www.thispantry.com",
                  })
                  .catch((error) => console.log("Error sharing", error));
              }}
            >
              <InviteIcon className="h-3 mr-2 fill-secondaryText" />
              <Typography variant="caption" component="p">
                Invite&nbsp;Friends
              </Typography>
            </Button>
          )}
        </div>
      </div>
      <div className="overflow-x-auto">
        <InfiniteScroll
          dataLength={suggestions.length}
          next={fetchSuggestions}
          hasMore={hasMore}
          loader={<Spinner />}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.9}
          className="flex"
        >
          <div className="grid grid-flow-col auto-cols-[minmax(300px,1fr)] md:auto-cols-[minmax(400px,1fr)] gap-4">
            {suggestions.map((user) => (
              <div key={user._id} className="pb-4">
                <ProfileSearchObject profile={user} border={false} padding="p-0" />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default EndOfFeed;
