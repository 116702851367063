//Import redux types
import {
  IMAGE_UPLOAD_ADD,
  IMAGE_UPLOAD_REMOVE,
  IMAGE_UPLOAD_ERROR,
  IMAGE_UPLOAD_RESET,
  IMAGE_UPLOAD_CROP,
  IMAGE_UPLOAD_CROP_ERROR,
  IMAGE_UPLOAD_REORDER,
  IMAGE_UPLOAD_OPEN_CROP,
  IMAGE_UPLOAD_CLOSE_CROP,
  SET_ORIGINAL_IMAGES,
} from "../actions/types";

//Initial state object
const initialState = {
  images: [],
  originalImages: [],
  modals: {
    crop: false,
    cropIndex: null,
  },
  errors: [],
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case SET_ORIGINAL_IMAGES:
      console.log(payload);
      //Append payload to images, if there are less than 10
      return {
        ...state,
        images: state.originalImages ? [...state.originalImages, ...payload].slice(0, 10) : [...payload].slice(0, 10),
        originalImages: state.originalImages
          ? [...state.originalImages, ...payload].slice(0, 10)
          : [...payload].slice(0, 10),
      };
    case IMAGE_UPLOAD_ADD:
      return {
        ...state,
        images: [...state.images, payload],
      };
    case IMAGE_UPLOAD_REMOVE:
      return {
        ...state,
        images: state.images.filter((image, index) => index !== payload),
      };
    case IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    case IMAGE_UPLOAD_RESET:
      return {
        ...state,
        images: [],
        originalImages: [],
        errors: [],
        modals: {
          crop: false,
          cropIndex: null,
        },
      };
    case IMAGE_UPLOAD_CROP:
      return {
        ...state,
        images: state.images.map((image, index) =>
          index === payload.index ? { ...image, cropped: payload.croppedImageData } : image
        ),
        modals: {
          ...state.modals,
          crop: false,
          cropIndex: null,
        },
      };
    case IMAGE_UPLOAD_OPEN_CROP:
      return {
        ...state,
        modals: {
          ...state.modals,
          crop: true,
          cropIndex: payload,
        },
      };
    case IMAGE_UPLOAD_CLOSE_CROP:
      return {
        ...state,
        modals: {
          ...state.modals,
          crop: false,
          cropIndex: null,
        },
      };
    case IMAGE_UPLOAD_CROP_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    case IMAGE_UPLOAD_REORDER:
      return {
        ...state,
        images: payload,
      };

    default:
      return state;
  }
}
