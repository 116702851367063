import api from "../api/api";

import store, { history } from "../store";

//Redux types
import {
  WALKTHROUGH_GET_RECIPE,
  WALKTHROUGH_RECIPE_ERROR,
  WALKTHROUGH_SET_SECTION,
  WALKTHROUGH_SET_INGREDIENT,
  RESET_WALKTHROUGH,
  WALKTHROUGH_SCALE_RECIPE,
  WALKTHROUGH_SCALE_SECTION,
} from "./types";

//Initial get recipe
export const getWalkthroughRecipe = (recipeID) => async (dispatch) => {
  try {
    //Get recipe
    const res = await api.get(`/recipes/walkthrough/${recipeID}`);

    const { recipe, user } = res.data;

    let payload = {
      recipe: recipe,
      user: user,
    };

    dispatch({
      type: WALKTHROUGH_GET_RECIPE,
      payload: payload,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: WALKTHROUGH_RECIPE_ERROR,
    });
  }
};

//Go to section
export const setWalkthroughSection = (sectionIndex) => (dispatch) => {
  dispatch({
    type: WALKTHROUGH_SET_SECTION,
    payload: sectionIndex,
  });
};

//Recipe complete

//Edit ingredient amount
export const updateSectionIngredient = (sectionIndex, ingredientIndex, ingredient) => (dispatch) => {
  const state = store.getState();
  const updatedRecipe = {
    ...state.walkthrough.recipe,
    sections: state.walkthrough.recipe.sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          ingredients: section.ingredients.map((sectionIngredient, index) => {
            if (index === ingredientIndex) {
              console.log(ingredient);
              return ingredient;
            }
            return sectionIngredient;
          }),
        };
      }
      return section;
    }),
  };

  dispatch({
    type: WALKTHROUGH_SET_INGREDIENT,
    payload: updatedRecipe,
  });
};

export const resetWalkthrough = () => (dispatch) => {
  dispatch({
    type: RESET_WALKTHROUGH,
  });
};

//TODO FIRST: Scaling should also scale yield, servings values
export const scaleSection = (sectionIndex, scaleFactor) => (dispatch, getState) => {
  const state = getState();
  const section = JSON.parse(JSON.stringify(state.walkthrough.recipe.sections[sectionIndex]));

  const scaledIngredients = section.ingredients.map((ingredient) => {
    if (!ingredient.unit.display.magnitude) return ingredient;

    return {
      ...ingredient,
      unit: {
        ...ingredient.unit,
        display: {
          ...ingredient.unit.display,
          magnitude: (parseFloat(ingredient.unit.display.magnitude) * scaleFactor).toString(),
        },
        anchor: ingredient.unit.anchor.magnitude
          ? {
              ...ingredient.unit.anchor,
              magnitude: (parseFloat(ingredient.unit.anchor.magnitude) * scaleFactor).toString(),
            }
          : ingredient.unit.anchor,
      },
    };
  });

  dispatch({
    type: WALKTHROUGH_SCALE_SECTION,
    payload: {
      sectionIndex,
      ingredients: scaledIngredients,
    },
  });
};

export const scaleRecipe = (scaleFactor) => (dispatch, getState) => {
  const state = getState();
  const sections = JSON.parse(JSON.stringify(state.walkthrough.recipe.sections));

  const scaledSections = sections.map((section) => ({
    ...section,
    ingredients: section.ingredients.map((ingredient) => {
      if (!ingredient.unit.display.magnitude) return ingredient;

      return {
        ...ingredient,
        unit: {
          ...ingredient.unit,
          display: {
            ...ingredient.unit.display,
            magnitude: (parseFloat(ingredient.unit.display.magnitude) * scaleFactor).toString(),
          },
          anchor: ingredient.unit.anchor.magnitude
            ? {
                ...ingredient.unit.anchor,
                magnitude: (parseFloat(ingredient.unit.anchor.magnitude) * scaleFactor).toString(),
              }
            : ingredient.unit.anchor,
        },
      };
    }),
  }));

  console.log(scaledSections);

  dispatch({
    type: WALKTHROUGH_SCALE_RECIPE,
    payload: scaledSections,
  });
};
