import { v4 } from "uuid";

import api from "../api/api";

import store, { history } from "../store";

//Notifications
import { askNotificationPerms } from "../sw";

//Alerts
import { setAlert } from "./alert";

//Capacitor
import { SecureStorage } from "../utils/secureStorage";
//Register
import { setRegisterErrors, setSignupStage } from "./register";
//Redux types
import {
  SHOW_REGISTER_MODAL,
  HIDE_REGISTER_MODAL,
  SHOW_LOGIN_MODAL,
  HIDE_LOGIN_MODAL,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  LOGOUT_ERROR,
  SET_TOKENS_FROM_CALLBACK,
  SET_CURRENT_PROFILE,
  CURRENT_PROFILE_ERROR,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_ERROR,
} from "./types";

//Config
import { SIGNUP_STAGES } from "../components/auth/register/config/RegisterConfig";

//Utility Functions
function meetsAgeRequirement(dob) {
  // Parse the provided DOB
  const birthDate = new Date(dob);

  // Get the current date
  const currentDate = new Date();

  // Calculate the age by subtracting the birth year from the current year
  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // Adjust the age if the birth month and day haven't occurred this year yet
  const monthDifference = currentDate.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }

  // Check if age is greater than or equal to 13
  return age >= 13;
}

//Register
export const signup = (formData) => async (dispatch) => {
  try {
    const { email, username, name, dob, password1, password2 } = formData;
    //Catch user error in data and relay to frontend state

    let errors;

    if (password1 !== password2) {
      errors = {
        ...errors,
        password1: { isValid: false, error: "Passwords must match" },
        password2: { isValid: false, error: "Passwords must match" },
      };
    }

    //Check is passwords have a capital, number, special char, and are at least 8 characters long
    const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    if (!passwordPattern.test(password1)) {
      errors = {
        ...errors,
        password1: { isValid: false, error: "Invalid password format" },
        password2: {
          isValid: false,
          error: "Passwords must be 8 characters long and contain a capital letter, number, and special character",
        },
      };
    }
    //Check DOB to be over 13 years
    if (!meetsAgeRequirement(dob)) {
      errors = {
        ...errors,
        dob: { isValid: false, error: "Must be at least 13 to use thisPantry" },
      };
    }

    //Verify Email Structure
    const emailPattern = /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      errors = {
        ...errors,
        email: { isValid: false, error: "Invalid email format" },
      };
    }

    const namePattern = /^[A-Za-z-' ]{1,100}$/;
    if (!namePattern.test(name)) {
      errors = {
        ...errors,
        name: { isValid: false, error: "Invalid name format" },
      };
    }
    //Verify Username Structure
    const usernamePattern = /^[a-zA-Z0-9._-]{3,20}$/;
    if (!usernamePattern.test(username)) {
      errors = {
        ...errors,
        username: { isValid: false, error: "Invalid username format" },
      };
    }

    //TO DO: Block offensive usernames

    if (errors) {
      dispatch(setRegisterErrors("initial", errors));
      return;
    }

    const res = await api.post("/auth/register", formData, {
      validateStatus: (status) => {
        return (status >= 200 && status < 300) || status === 400;
      },
    });

    //Catch errors
    if (res.status === 400) {
      dispatch(setRegisterErrors("initial", res.data));
      return;
    }
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    // dispatch(setSignupStage(SIGNUP_STAGES.RESTRICTIONS));
    // Navigate to /welcome/${signupstage}
    window.location.href = `/welcome/${SIGNUP_STAGES.RESTRICTIONS}`;
  } catch (error) {
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};
//Login
export const login = (formData) => async (dispatch) => {
  try {
    //Catch a 400 error response, and attach message to payload for login fail
    const res = await api.post("/auth/login", formData, {
      validateStatus: (status) => {
        return (status >= 200 && status < 300) || status === 400;
      },
    });

    if (res.status === 400) {
      dispatch({
        type: LOGIN_FAIL,
        payload: res.data,
      });
      return;
    }

    //Determine Signup Stage
    if (res.data.user) {
      if (res.data.user.signupStage) {
        dispatch(setSignupStage(res.data.user.signupStage));
        // Navigate to /welcome/${signupstage}
        window.location.href = `/welcome/${res.data.user.signupStage}`;
      }
    }
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: null,
    });
  }
};
export const loadUser = () => async (dispatch) => {
  //Make request
  try {
    // Get token from secure storage
    const token = await SecureStorage.get("token");
    if (token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    //Get response
    const res = await api.get("/auth");

    // Check if we're on a signup stage page
    const isOnSignupPage = window.location.pathname.startsWith("/welcome/");

    if (res.data.user.signupStage) {
      dispatch(setSignupStage(res.data.user.signupStage));
      // Only show register modal if not on signup page
      if (!isOnSignupPage) {
        // Redirect to appropriate signup page
        window.location.href = `/welcome/${res.data.user.signupStage}`;
      }
    }

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const setTokensFromCallback = (token, refreshToken, navigate) => async (dispatch) => {
  try {
    // Set tokens in secure storage
    await SecureStorage.set("token", token);
    await SecureStorage.set("refreshToken", refreshToken);

    // Set default headers for future requests
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    dispatch({
      type: SET_TOKENS_FROM_CALLBACK,
      payload: {
        token,
        refreshToken,
      },
    });
    dispatch(loadUser());
    navigate("/");
  } catch (error) {
    console.log("Error setting tokens from callback");
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
//Logout
export const logout = () => async (dispatch) => {
  try {
    const state = store.getState();

    let refreshToken = state.auth.refreshToken;

    if (!refreshToken) {
      refreshToken = await SecureStorage.get("refreshToken");
    }

    const res = await api.post("/auth/logout", { refreshToken });

    // Clear tokens from secure storage
    await SecureStorage.remove("token");
    await SecureStorage.remove("refreshToken");

    // Clear auth header
    delete api.defaults.headers.common["Authorization"];

    dispatch({
      type: LOGOUT,
    });
  } catch (error) {
    dispatch({
      type: LOGOUT_ERROR,
    });
  }
};

export const deleteAccount = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/auth/delete", formData, {
      validateStatus: (status) => {
        return (status >= 200 && status < 300) || status === 400;
      },
    });

    if (res.status === 400) {
      dispatch(setAlert(res.data.msg, "warning"));
      return;
    }

    dispatch(setAlert("Your account has been deleted. We're sorry to see you go.", "success"));

    dispatch({
      type: DELETE_ACCOUNT,
    });
  } catch (err) {
    dispatch(setAlert("Something went wrong. Please refresh and try again.", "warning"));
    dispatch({
      type: DELETE_ACCOUNT_ERROR,
    });
  }
};

//Modal Handling
//Register
export const hideRegisterModal = () => (dispatch) => {
  dispatch({
    type: HIDE_REGISTER_MODAL,
  });
};

export const showRegisterModal = () => (dispatch) => {
  dispatch({
    type: SHOW_REGISTER_MODAL,
  });
};

//Login
export const showLoginModal = () => (dispatch) => {
  dispatch({
    type: SHOW_LOGIN_MODAL,
  });
};

export const hideLoginModal = () => (dispatch) => {
  dispatch({
    type: HIDE_LOGIN_MODAL,
  });
};
