import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { connect } from "react-redux";
import { cropImageInUpload, removeImageFromUpload } from "../../../../actions/media";
import { setAlert } from "../../../../actions/alert";
import { getCroppedImg } from "../../../../utils/image"; // This is a utility function you need to implement

import { Button, Typography } from "@mui/material";

const ImageCropper = ({ media: { images, modals }, cropImageInUpload, removeImageFromUpload, setAlert }) => {
  const image = images[modals.cropIndex];

  const [crop, setCrop] = useState(image && image.cropped && image.cropped.crop ? image.cropped.crop : { x: 0, y: 0 });
  const [zoom, setZoom] = useState(image && image.cropped && image.cropped.zoom ? image.cropped.zoom : 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  //Removal state
  const [areYouSure, setAreYouSure] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropConfirm = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image.previewUrl, croppedAreaPixels); //Do this again on all images in the fire to backend, append to formData
      cropImageInUpload(modals.cropIndex, { croppedImage, crop, zoom, croppedAreaPixels });
      setAlert("Image cropped", "success");
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels, cropImageInUpload, modals.cropIndex]);

  return (
    <div>
      <div className="w-full relative">
        <div className="relative h-full w-full min-h-[600px]">
          {image && !image.source ? (
            <Cropper
              image={image.previewUrl}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              initialCroppedAreaPixels={image && image.cropped && image.cropped.croppedAreaPixels}
            />
          ) : (
            <div>
              <div className="w-full aspect-square">
                <img src={image.previewUrl} alt="preview" className="w-full h-full object-cover" />
              </div>
              <div className="px-4 py-3 pb-0">
                <Typography variant="caption" className="text-secondaryText-600">
                  This image is from an imported recipe and cannot be edited.
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between pt-3">
        {areYouSure ? (
          <Button
            size="small"
            variant="contained"
            color="danger"
            fullWidth={image?.source}
            onClick={() => removeImageFromUpload(modals.cropIndex)}
            disableElevation
          >
            <Typography>Yes, Remove</Typography>
          </Button>
        ) : (
          <Button variant="contained" onClick={() => setAreYouSure(true)} fullWidth={image?.source} disableElevation>
            <Typography>Remove</Typography>
          </Button>
        )}
        {!image.source && (
          <Button onClick={onCropConfirm} variant="contained" color="primary" disableElevation>
            <Typography>Crop</Typography>
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  media: state.media,
});
export default connect(mapStateToProps, { cropImageInUpload, removeImageFromUpload, setAlert })(ImageCropper);
