import React from "react";
import { Typography } from "@mui/material";

const RecipeMacrosItem = ({ data }) => {
  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <Typography variant="subtitle1" className="font-normal leading-snug">
          {data.name}
        </Typography>
        <div className="flex-1 border-b border-separator mx-2"></div>
        <div className="flex flex-row">
          <Typography variant="body2">{data.amount}</Typography>
          {data.unit && (
            <Typography variant="body2" className="ml-2">
              {data.unit.name}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

const SectionMacrosModal = ({ macros, servingSize, title }) => {
  return (
    <div>
      <div className="w-full p-4 bg-neutral-100 ">
        <Typography variant="h6" className="font-normal ">
          {title}
        </Typography>
      </div>
      <div className="px-4 py-2">
        <Typography variant="subtitle2" className="font-normal leading-snug">
          1 Serving
        </Typography>
        <Typography variant="subtitle2" className="font-normal leading-snug text-xs italic text-secondaryText-600">
          Serving Size: {servingSize}
        </Typography>
      </div>
      <div className="px-4 py-2 mb-4">
        {macros.map((macro) => (
          <RecipeMacrosItem key={macro.id} data={macro} />
        ))}
      </div>
    </div>
  );
};

export default SectionMacrosModal;
