import React, { useEffect, useState } from "react";
import api from "../../api/api";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Divider } from "@mui/material";

import { ReactComponent as PlusLogo } from "../plus/res/plus/pluslogo-duolight.svg";
const CheckoutPriceSelector = ({ auth: { isAuthenticated, isPlus } }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [clientSecretLoading, setClientSecretLoading] = useState(true);
  const [clientSecretError, setClientSecretError] = useState(null);
  //Get customer session to prefill
  const getCustomerClientSecret = async () => {
    try {
      const response = await api.post("/stripe/create-pricing-table");
      setClientSecret(response.data.clientSecret);
      setClientSecretLoading(false);
      setClientSecretError(null);
    } catch (error) {
      console.error(error);
      setClientSecretLoading(false);
      setClientSecretError(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getCustomerClientSecret();
    }
  }, [isAuthenticated]);

  return (
    <div>
      {isAuthenticated ? (
        <>
          {isPlus ? (
            <div>
              <div>already plus</div>
            </div>
          ) : (
            <>
              {clientSecretLoading ? (
                <>
                  <div>Skeleton price selector</div>
                </>
              ) : (
                <>
                  {clientSecretError ? (
                    <div>
                      <div>Error loading price selector</div>
                    </div>
                  ) : (
                    <div className="bg-mainGreen">
                      <div className="py-16 md:py-32 flex flex-col xl:flex-row gap-8 xl:gap-16 items-center max-w-4xl mx-auto">
                        <div className="w-full max-w-[400px] px-8">
                          <Typography variant="h5" className="text-white">
                            <span>
                              Try <PlusLogo className="h-16 w-auto my-2 md:mx-8 fill-mainGreen-700" />
                            </span>
                          </Typography>
                          <Typography variant="h5" className="text-white text-right">
                            free for 7 days
                          </Typography>
                          <Typography variant="subtitle2" className="font-normal mt-4 text-white w-full">
                            Then subscribe bi-weekly,<br></br>monthly, or annually. Cancel anytime.
                          </Typography>
                        </div>
                        <Divider orientation="vertical" flexItem className="border-white" />
                        <div className="w-full">
                          <stripe-pricing-table
                            pricing-table-id="prctbl_1QRKc1EG8mQA0pyFFnubxZaz"
                            publishable-key="pk_test_51PKuT2EG8mQA0pyFza3a2NTP1WHbvb8AYmvb6JI79UdmQJx0xZm1XLqkbkrgMvSiQwp1edtOvJfG2wCHGrL8xFm200OTsmaHHe"
                            customer-session-client-secret={clientSecret}
                          ></stripe-pricing-table>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <div>no auth</div>
      )}
    </div>
  );
};

CheckoutPriceSelector.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CheckoutPriceSelector);
