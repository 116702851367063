import React, { useState, Fragment } from "react";

import { Typography, ButtonBase } from "@mui/material";

//Modal Components
import CustomModal from "../../../../modal/CustomModal";
import IngredientModal from "../modals/IngredientModal";

//Utility Functions
import { decimalToFraction, convertToTitleCase } from "../../../../../utils/recipe";
//String formatting

const formatAmountUnit = (ingredient) => {
  const unit = ingredient.unit.display;
  let formattedAmount = "";
  let formattedUnit = "";

  if (unit && unit.magnitude && unit.magnitude !== "") {
    formattedAmount = decimalToFraction(unit.magnitude);
  }

  if (unit && unit.text !== "" && unit.text !== "not_exact" && unit.text !== "self" && unit.type !== "unknown") {
    formattedUnit = unit.text;
  }

  let showSpace = false;

  if (formattedAmount !== "" && formattedUnit !== "") {
    showSpace = true;
  }

  return `${formattedAmount} ${formattedUnit}${showSpace ? " " : ""}`;
};

const formatIngredient = (ingredient) => {
  //Convert ingredient data to a string for the top line
  let formattedName =
    ingredient.name && ingredient.name !== "" ? convertToTitleCase(ingredient.name) : "Unknown Ingredient";

  return `${formattedName}`;
};

const formatState = (ingredient) => {
  let formattedState = "";

  if (ingredient.state && ingredient.state !== "") {
    formattedState = convertToTitleCase(ingredient.state);
  }

  if (formattedState !== "") {
    return `, ${formattedState}`;
  }
};

const Ingredient = ({ section, sectionIndex, ingredient, index }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <CustomModal open={showModal} handleClose={handleCloseModal} type="bottom">
        <IngredientModal
          section={section}
          sectionIndex={sectionIndex}
          ingredient={ingredient}
          index={index}
          handleClose={handleCloseModal}
        />
      </CustomModal>
      <li className="my-2 cursor-pointer marker:text-mainGreen" onClick={() => handleOpenModal()}>
        <div>
          <Typography variant="subtitle1" className="font-medium">
            <span className="font-normal">{formatAmountUnit(ingredient)}</span>
            {formatIngredient(ingredient)}
            {ingredient.state !== "" && (
              <span className="font-normal italic text-secondaryText">{formatState(ingredient)}</span>
            )}
          </Typography>
          {ingredient.note && ingredient.note !== "" && (
            <Typography variant="body2" className="text-secondaryText">
              ({ingredient.note})
            </Typography>
          )}
        </div>
      </li>
    </div>
  );
};

export default Ingredient;
