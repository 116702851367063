import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pluralize } from "inflection";
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  List,
  Divider,
  IconButton,
} from "@mui/material";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faChevronDown, faChevronUp, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/svg/edit.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/svg/leaf-solid.svg";

//Components
import CustomModal from "../../../modal/CustomModal";
import RecipeSectionIngredient from "./RecipeSectionIngredient";
import RecipeSectionStep from "./RecipeSectionStep";
import RecipeEditSectionModal from "./RecipeEditSectionModal";
import RecipeSectionVideoModal from "./RecipeSectionVideoModal";
//Actions
import {
  processIngredients,
  addStep,
  toggleRecipeMacrosModal,
  toggleRecipeOutputModal,
  toggleRecipeTimingModal,
} from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";

//Utils
import { convertToTitleCase } from "../../../../utils/recipe";

//DnD
import { Droppable } from "@hello-pangea/dnd";

//ReactPlayer
import ReactPlayer from "react-player";

const RecipeSection = ({
  processIngredients,
  addStep,
  toggleRecipeMacrosModal,
  toggleRecipeOutputModal,
  toggleRecipeTimingModal,
  section,
  sectionIndex,
  recipe,
  setAlert,
}) => {
  //Ingredient Input State
  const [ingredientInput, setIngredientInput] = useState("");

  //Focus state
  const [highlightIngredient, setHighlightIngredient] = useState(false);
  const [highlightStep, setHighlightStep] = useState(false);

  const [showTiming, setShowTiming] = useState(false);
  const [showOutput, setShowOutput] = useState(false);

  const handleIngredientsSubmit = (ingredientText, sectionIndex) => {
    // Split the text into lines and process each line
    var lines = ingredientText
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0 && !/^[\s\p{P}]*$/.test(line));

    const added = processIngredients(lines, sectionIndex);

    if (added) {
      setIngredientInput("");
      setHighlightIngredient(false);
    }
  };

  const handleIngredientsChange = (e) => {
    setIngredientInput(e.target.value);
  };

  //Steps Input State
  const [stepInput, setStepInput] = useState("");

  const handleStepSubmit = (index) => {
    //Trim text
    let trimmed = stepInput.trim();

    if (trimmed === "") {
      setAlert("Step cannot be empty", "warning");
      return;
    }

    if (trimmed.length > 1024) {
      setAlert("Step cannot be longer than 1024 characters", "warning");
      return;
    }

    addStep(index, trimmed);

    setStepInput("");
    setHighlightStep(false);
  };

  const handleStepChange = (e) => {
    if (e.target.value.length > 1024) {
      setAlert("Step cannot be longer than 1024 characters", "warning");
      return;
    }
    setStepInput(e.target.value);
  };

  const [titleHover, setTitleHover] = useState(false);

  //Modal state
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleShowEditSectionModal = () => {
    setShowEditSectionModal(true);
  };

  const handleCloseEditSectionModal = () => {
    setShowEditSectionModal(false);
  };

  const handleShowVideoModal = () => {
    setShowVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };

  const handleTitleMouseEnter = () => {
    setTitleHover(true);
  };

  const handleTitleMouseLeave = () => {
    setTitleHover(false);
  };

  const checkShowTiming = () => {
    let shouldShow = false;
    if (section.timing) {
      if (section.timing.prep) {
        if (section.timing.prep.hours !== "" || section.timing.prep.minutes !== "") {
          shouldShow = true;
        }
      }
      if (section.timing.cook) {
        if (section.timing.cook.hours !== "" || section.timing.cook.minutes !== "") {
          shouldShow = true;
        }
      }
      if (section.timing.rest) {
        if (section.timing.rest.hours !== "" || section.timing.rest.minutes !== "") {
          shouldShow = true;
        }
      }
      if (section.timing.total) {
        if (section.timing.total.hours !== "" || section.timing.total.minutes !== "") {
          shouldShow = true;
        }
      }
    }
    setShowTiming(shouldShow);
  };

  const checkShowOutput = () => {
    let shouldShow = false;
    if (section.output) {
      if (section.output.servings && section.output.servings.length > 0) {
        shouldShow = true;
      }
      if (section.output.yield) {
        if (section.output.yield.amount && section.output.yield.amount.length > 0) {
          shouldShow = true;
        }
        if (section.output.yield?.ingredient?.name && section.output.yield?.ingredient?.name?.length > 0) {
          shouldShow = true;
        }
        if (section.output.yield?.unit?.name && section.output.yield?.unit?.name?.length > 0) {
          shouldShow = true;
        }
      }
    }
    console.log(shouldShow);
    setShowOutput(shouldShow);
  };

  useEffect(() => {
    checkShowTiming();
    checkShowOutput();
  }, [section]);

  return (
    <div key={sectionIndex} className="border border-separator rounded-md shadow-sm bg-background">
      <CustomModal open={showEditSectionModal} handleClose={handleCloseEditSectionModal}>
        <RecipeEditSectionModal
          section={section}
          sectionIndex={sectionIndex}
          handleClose={handleCloseEditSectionModal}
        />
      </CustomModal>
      <CustomModal open={showVideoModal} handleClose={handleCloseVideoModal}>
        <RecipeSectionVideoModal section={section} sectionIndex={sectionIndex} handleClose={handleCloseVideoModal} />
      </CustomModal>

      <div className="flex flex-row items-center p-4 md:px-8 md:py-6 bg-neutral-100 rounded-t-md">
        <Typography
          variant="h5"
          className="text-primaryText cursor-pointer leading-none"
          onClick={handleShowEditSectionModal}
          onMouseEnter={handleTitleMouseEnter}
          onMouseLeave={handleTitleMouseLeave}
        >
          {section.title}
        </Typography>
        {titleHover && <EditIcon className="h-3 fill-secondaryText-600 ml-3" />}
      </div>

      {section &&
        section?.source &&
        (section.source?.type ? (
          <div className="px-4 py-2 md:px-8">
            <Typography variant="caption" className="text-secondaryText leading-none">
              {section.source.type}:
            </Typography>
            {section.source.text === "thisPantry+" ? (
              <div className="flex flex-row gap-1 items-center">
                <Typography variant="caption" className="text-primaryText leading-none">
                  thisPantry<span className="font-semibold text-mainGreen">+</span>
                </Typography>
                {section.source.text === "thisPantry+" && <PlusIcon className="w-3 fill-mainGreen" />}
              </div>
            ) : (
              <div>
                <Typography variant="caption" className="text-primaryText leading-none">
                  {section.source.text}
                </Typography>
              </div>
            )}

            {section.source.url && (
              <a href={section.source.url} target="_blank" rel="noopener noreferrer" className="truncate leading-none">
                <Typography variant="caption" className="font-normal text-mainGreen leading-none">
                  {section.source.url}
                </Typography>
              </a>
            )}
          </div>
        ) : null)}
      {section && section?.video && section?.video.length > 0 && ReactPlayer.canPlay(section.video) && (
        <div className="w-full mt-2" style={{ position: "relative", paddingTop: "56.25%", borderRadius: "16px" }}>
          <ReactPlayer
            url={section.video}
            controls={true}
            width="100%"
            height="100%"
            style={{ position: "absolute", top: 0, left: 0 }}
          />
        </div>
      )}
      <div className="p-4 md:px-8">
        <Button
          className="border-secondaryText-200 hover:border-secondaryText"
          variant="contained"
          color="secondary"
          disableElevation
          disableRipple
          onClick={handleShowVideoModal}
          disabled={recipe.create.ingredientsProcessing.loading}
          size="small"
          fullWidth
        >
          <Typography variant="caption" className="text-primaryText">
            {section && section?.video ? "+ Edit Embedded Video" : "+ Attach Video to Section"}
          </Typography>
        </Button>
      </div>
      <div className="p-4 md:px-8 md:py-6">
        <div>
          <div className="text-primaryText">
            <Typography variant="h6" className="font-normal leading-none">
              Ingredients:
            </Typography>
          </div>
          <div>
            {section.ingredients.length > 0 && (
              <Droppable droppableId={`section-${sectionIndex}`}>
                {(provided, snapshot) => (
                  <List
                    className={`list-disc pl-4 ${snapshot.isDraggingOver ? "bg-gray-50 rounded-md" : ""}`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {section.ingredients.map((ingredient, ingredientIndex) => (
                      <RecipeSectionIngredient
                        key={`ingredient-${sectionIndex}-${ingredientIndex}`}
                        ingredient={ingredient}
                        ingredientIndex={ingredientIndex}
                        sectionIndex={sectionIndex}
                      />
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            )}
          </div>
          <div className="my-2">
            <TextField
              fullWidth
              label="Add Ingredient"
              variant="outlined"
              value={ingredientInput}
              onChange={(e) => handleIngredientsChange(e)}
              onFocus={() => setHighlightIngredient(true)}
              onBlur={() => setHighlightIngredient(ingredientInput.length > 0)}
              InputProps={{
                startAdornment:
                  recipe.create &&
                  recipe.create.ingredientsProcessing &&
                  recipe.create.ingredientsProcessing.loading ? (
                    <InputAdornment position="start">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ) : null,
              }}
              disabled={recipe.create.ingredientsProcessing.loading}
            />
            {highlightIngredient ? (
              <Button
                className="mt-2"
                variant="contained"
                color="primary"
                disableElevation
                disableRipple
                onClick={() => handleIngredientsSubmit(ingredientInput, sectionIndex)}
                disabled={recipe.create.ingredientsProcessing.loading}
                size="small"
                fullWidth
              >
                <Typography variant="caption">+ Add Ingredient</Typography>
              </Button>
            ) : (
              <Button
                className="mt-2 border-secondaryText-200 hover:border-secondaryText"
                variant="contained"
                color="secondary"
                disableElevation
                disableRipple
                onClick={() => handleIngredientsSubmit(ingredientInput, sectionIndex)}
                disabled={recipe.create.ingredientsProcessing.loading}
                size="small"
                fullWidth
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Ingredient
                </Typography>
              </Button>
            )}
          </div>
        </div>
        <div className="py-4">
          <div className="text-primaryText">
            <Typography variant="h6" className="font-normal">
              Steps:
            </Typography>
          </div>
          <div>
            {section.steps.length > 0 && (
              <List className="list-decimal pl-4">
                {section.steps.map((step, stepIndex) => (
                  <RecipeSectionStep key={stepIndex} step={step} stepIndex={stepIndex} sectionIndex={sectionIndex} />
                ))}
              </List>
            )}
          </div>
          <div className="my-2">
            <TextField
              fullWidth
              label="Add Step"
              variant="outlined"
              onFocus={() => setHighlightStep(true)}
              onBlur={() => setHighlightStep(stepInput.length > 0)}
              value={stepInput}
              onChange={(e) => handleStepChange(e)}
              multiline
            />
            {highlightStep ? (
              <Button
                className="mt-2"
                variant="contained"
                color="primary"
                disableElevation
                disableRipple
                onClick={() => handleStepSubmit(sectionIndex)}
                size="small"
                fullWidth
              >
                <Typography variant="caption">+ Add Step</Typography>
              </Button>
            ) : (
              <Button
                className="mt-2 border-secondaryText-200 hover:border-secondaryText"
                variant="contained"
                color="secondary"
                disableElevation
                disableRipple
                onClick={() => handleStepSubmit(sectionIndex)}
                size="small"
                fullWidth
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Step
                </Typography>
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full mt-4 gap-4 md:gap-8">
          <div>
            <Typography variant="h6" className="leading-none font-normal">
              Additional Info:
            </Typography>
          </div>
          {showOutput ? (
            <div>
              <div
                className="flex flex-col gap-2 cursor-pointer"
                onClick={() => toggleRecipeOutputModal(true, sectionIndex)}
              >
                <div className="flex flex-col md:flex-row justify-between gap-2 items-start mb-4">
                  {section.output.servings && (
                    <div className="flex flex-col gap-2 w-full md:w-1/2">
                      <Typography variant="subtitle1" className="leading-none font-normal">
                        Servings: {section.output.servings}
                      </Typography>
                      {section.servingSize && (
                        <Typography variant="subtitle1" className="leading-none font-normal">
                          Serving Size: {section.servingSize}
                        </Typography>
                      )}
                    </div>
                  )}
                  {section.output.yield && (
                    <div className="flex flex-col gap-2 mt-4 md:mt-0 w-full md:w-1/2">
                      <Typography variant="subtitle1" className="leading-none font-normal">
                        Yield:
                      </Typography>
                      <Typography variant="subtitle1" className="leading-none font-normal">
                        {`${section.output?.yield?.amount} ${
                          section.output.yield?.unit?.name ? section.output.yield.unit.name : " "
                        }`}
                        <span className="font-medium">
                          {` ${convertToTitleCase(
                            section.output.yield?.ingredient?.name ? section.output.yield.ingredient.name : ""
                          )}`}
                        </span>
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disableElevation
                  onClick={() => toggleRecipeOutputModal(true, sectionIndex)}
                  size="small"
                >
                  <Typography variant="caption" className="text-primaryText flex flex-row items-center">
                    <EditIcon className="h-3 mr-2" />
                    Edit Output
                  </Typography>
                </Button>
              </div>
            </div>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disableElevation
              onClick={() => toggleRecipeOutputModal(true, sectionIndex)}
              size="small"
            >
              <Typography variant="caption" className="text-primaryText">
                + Add Output
              </Typography>
            </Button>
          )}

          {showTiming ? (
            <div>
              <div
                className="flex flex-col gap-2 cursor-pointer"
                onClick={() => toggleRecipeTimingModal(true, sectionIndex)}
              >
                <Typography variant="h6" className="leading-none font-normal">
                  Timing:
                </Typography>
                <div className="grid grid-cols-2 gap-4 py-2">
                  {section?.timing &&
                    section?.timing?.prep &&
                    (section?.timing?.prep?.hours > 0 || section?.timing?.prep?.minutes > 0) && (
                      <div>
                        <Typography variant="body2" component="p" className="font-normal">
                          Prep:
                        </Typography>
                        <Typography variant="body2" component="p" className="text-secondaryText font-normal">
                          {`${section?.timing?.prep.hours > 0 ? `${section?.timing?.prep.hours} hrs ` : ""}${
                            section?.timing?.prep.minutes
                          } mins`}
                        </Typography>
                      </div>
                    )}
                  {section?.timing &&
                    section?.timing?.rest &&
                    (section?.timing?.rest?.hours > 0 || section?.timing?.rest?.minutes > 0) && (
                      <div>
                        <Typography variant="body2" component="p" className="font-normal">
                          Rest:
                        </Typography>
                        <Typography variant="body2" component="p" className="text-secondaryText font-normal">
                          {`${section?.timing?.rest.hours > 0 ? `${section?.timing?.rest.hours} hrs ` : ""}${
                            section?.timing?.rest.minutes
                          } mins`}
                        </Typography>
                      </div>
                    )}
                  {section?.timing &&
                    section?.timing?.cook &&
                    (section?.timing?.cook?.hours > 0 || section?.timing?.cook?.minutes > 0) && (
                      <div>
                        <Typography variant="body2" component="p" className="font-normal">
                          Cook:
                        </Typography>
                        <Typography variant="body2" component="p" className="text-secondaryText font-normal">
                          {`${section?.timing?.cook.hours > 0 ? `${section?.timing?.cook.hours} hrs ` : ""}${
                            section?.timing?.cook.minutes
                          } mins`}
                        </Typography>
                      </div>
                    )}
                  {section?.timing &&
                    section?.timing?.total &&
                    (section?.timing?.total?.hours > 0 || section?.timing?.total?.minutes > 0) && (
                      <div>
                        <Typography variant="body2" component="p" className="font-normal">
                          Total:
                        </Typography>
                        <Typography variant="body2" component="p" className="text-secondaryText font-normal">
                          {`${section?.timing?.total.hours > 0 ? `${section?.timing?.total.hours} hrs ` : ""}${
                            section?.timing?.total.minutes
                          } mins`}
                        </Typography>
                      </div>
                    )}
                </div>
              </div>
              <div className="mt-2">
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disableElevation
                  onClick={() => toggleRecipeTimingModal(true, sectionIndex)}
                  size="small"
                >
                  <Typography variant="caption" className="text-primaryText flex flex-row items-center">
                    <EditIcon className="h-3 mr-2" />
                    Edit Timing
                  </Typography>
                </Button>
              </div>
            </div>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disableElevation
              onClick={() => toggleRecipeTimingModal(true, sectionIndex)}
              size="small"
            >
              <Typography variant="caption" className="text-primaryText">
                + Add Timing
              </Typography>
            </Button>
          )}

          {section.macros && section.macros.length > 0 ? (
            <div>
              <div
                className="flex flex-col gap-1 cursor-pointer"
                onClick={() => toggleRecipeMacrosModal(true, sectionIndex)}
              >
                <Typography variant="h6" className="leading-none font-normal">
                  Macronutrients:
                </Typography>
                <Typography variant="caption" className="leading-none font-normal italic">
                  Serving Size: {section.servingSize}
                </Typography>
              </div>
              <div className="py-2 mb-2">
                {section.macros.map((macro, i) => (
                  <div
                    className="flex flex-row justify-between items-center"
                    key={`${sectionIndex}-${macro.name}-${i}`}
                  >
                    <Typography variant="subtitle2" className="font-normal">
                      {macro.macronutrient?.display ? macro.macronutrient.display : macro.macronutrient.name}
                    </Typography>
                    <div className="flex-1 border-b border-separator mx-2"></div>
                    <div className="flex flex-row">
                      <Typography variant="body2">{macro.amount}</Typography>
                      {macro.macronutrient?.unit && (
                        <Typography variant="body2" className="ml-2">
                          {macro.macronutrient.unit}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disableElevation
                onClick={() => toggleRecipeMacrosModal(true, sectionIndex)}
                size="small"
              >
                <Typography variant="caption" className="text-primaryText flex flex-row items-center">
                  <EditIcon className="h-3 mr-2" />
                  Edit Macros
                </Typography>
              </Button>
            </div>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disableElevation
              onClick={() => toggleRecipeMacrosModal(true, sectionIndex)}
              size="small"
            >
              <Typography variant="caption" className="text-primaryText">
                + Add Macros
              </Typography>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

RecipeSection.propTypes = {
  processIngredients: PropTypes.func.isRequired,
  addStep: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  recipe: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  toggleRecipeMacrosModal: PropTypes.func.isRequired,
  toggleRecipeOutputModal: PropTypes.func.isRequired,
  toggleRecipeTimingModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
});

export default connect(mapStateToProps, {
  processIngredients,
  addStep,
  setAlert,
  toggleRecipeMacrosModal,
  toggleRecipeOutputModal,
  toggleRecipeTimingModal,
})(RecipeSection);
