import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element: Element, requireSignupComplete = true, ...rest }) => {
  const { isAuthenticated, loading, user } = useSelector((state) => state.auth);
  const location = useLocation();

  if (loading) {
    // Optionally render a loading indicator
    return <div>Loading...</div>;
  }

  // Check if user is authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Check if signup needs to be completed
  if (requireSignupComplete && user?.signupStage && user.signupStage > 0) {
    return <Navigate to={`/welcome/${user.signupStage}`} />;
  }

  return <Element {...rest} />;
};

PrivateRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
  requireSignupComplete: PropTypes.bool,
};

export default PrivateRoute;
