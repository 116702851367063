import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import api from "../../../api/api";

//DOM imports
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//MUI
import { Button, IconButton, Typography, Divider } from "@mui/material";
import { ReactComponent as TitleIcon } from "../../../assets/icons/svg/block.svg";

//Components
import Spinner from "../../layout/Spinner";
import BlockedProfile from "../components/BlockedProfile";

const Blocked = ({}) => {
  const [blocked, setBlocked] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBlocked = async () => {
    try {
      const res = await api.get("/auth/blocked");
      console.log(res.data);
      setBlocked(res.data);
      setLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const onUnblock = (id) => {
    setBlocked(blocked.filter((b) => b._id !== id));
  };

  useEffect(() => {
    fetchBlocked();
  }, []);

  return (
    <>
      <Helmet>
        <title>thisPantry - Block Settings</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:pt-6 bg-neutral-50 min-h-screen">
        <div className="w-full md:pl-[5rem]">
          <div className="px-4 py-3 md:px-8 md:py-6 md:mx-auto w-full md:max-w-[600px] border-b md:border border-separator md:rounded-[16px] bg-background">
            <div className="flex flex-col">
              <div className="my-4 md:mt-0">
                <Link className="hover:bg-transparent text-secondaryText p-0 justify-start" to="/settings">
                  <Typography variant="body2" className="body1 leading-none">{`< Go Back`}</Typography>
                </Link>
              </div>
              <div className="flex flex-row mt-">
                <div className="mr-4">
                  <TitleIcon className="h-[1.25rem] fill-red-600"></TitleIcon>
                </div>
                <div className="mb-4">
                  <Typography variant="h6" className="font-normal leading-none mb-1">
                    Blocked Users
                  </Typography>
                  <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                    View users you have blocked
                  </Typography>
                </div>
              </div>
            </div>
            <Divider />

            <div className="">
              {loading ? (
                <Spinner />
              ) : error ? (
                <div className="flex flex-col items-center justify-center mt-4 text-center py-8">
                  <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
                    An error occurred while fetching blocked users. Please refresh the page and try again.
                  </Typography>
                </div>
              ) : blocked.length > 0 ? (
                <div>
                  {blocked.map((b) => (
                    <BlockedProfile key={b._id} profile={b} onUnblock={onUnblock} />
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center mt-4 text-center py-8">
                  <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
                    You have not blocked any users.
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Blocked.propTypes = {};

export default connect(null, {})(Blocked);
