import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Button } from "@mui/material";

import Ingredient from "./Ingredient";
import { formatIngredient } from "../../../../../utils/recipe";

//TODO:
//Loading
//Error
//Show servings (section based, or entire recipe), open to scaling modal

const SectionIngredients = ({ walkthrough: { recipe, currentSection, loading, error } }) => {
  const [showMacrosModal, setShowMacrosModal] = useState(false);
  const [showServingsModal, setShowServingsModal] = useState(false);
  const [showYieldModal, setShowYieldModal] = useState(false);
  return (
    <div className="w-full lg:w-fit md:rounded-lg md:border md:border-separator bg-background">
      <div className="flex flex-col">
        <div className="w-full bg-neutral-100 px-4 md:px-8 py-3 md:rounded-t-lg md:border-b md:border-separator">
          <Typography variant="h6" className="font-normal">
            Ingredients:
          </Typography>
        </div>
        {recipe.sections[currentSection].servings && (
          <div className="px-4 md:px-8 pt-4 w-full border-b border-separator pb-4">
            {recipe.sections[currentSection].yield && (
              <Button
                onClick={() => setShowYieldModal(true)}
                className="p-0 hover:bg-transparent justify-start items-start text-left"
                disableRipple
              >
                <div className="pb-2">
                  <Typography variant="subtitle2" className="font-normal">
                    Yield:
                  </Typography>
                  <div className="flex flex-grow-0 gap-1">
                    {recipe.sections[currentSection].yield.amount && (
                      <Typography
                        variant="subtitle2"
                        className="font-normal"
                      >{`${recipe.sections[currentSection].yield.amount} `}</Typography>
                    )}
                    {recipe.sections[currentSection].yield.unit &&
                      recipe.sections[currentSection].yield.unit.shortName && (
                        <Typography
                          variant="subtitle2"
                          className="font-normal"
                        >{`${recipe.sections[currentSection].yield.unit.shortName} `}</Typography>
                      )}
                    {recipe.sections[currentSection].yield.ingredient &&
                      recipe.sections[currentSection].yield.ingredient.name && (
                        <Typography variant="subtitle2" className="font-medium">
                          {formatIngredient(
                            recipe.sections[currentSection].yield.ingredient,
                            recipe?.sections[currentSection]?.yield?.amount
                              ? recipe.sections[currentSection].yield.amount
                              : 1,
                            recipe?.sections[currentSection]?.yield?.unit?.type
                              ? recipe?.sections[currentSection]?.yield?.unit?.type
                              : null
                          )}
                        </Typography>
                      )}
                  </div>
                </div>
              </Button>
            )}
            <div className="">
              <div className="flex flex-row justify-between">
                <div className="w-1/2">
                  <Typography
                    variant="subtitle2"
                    className="font-normal leading-snug"
                  >{`${recipe.sections[currentSection].servings} servings`}</Typography>
                  {recipe.sections[currentSection].servingSize &&
                    recipe.sections[currentSection].servingSize !== "" && (
                      <Typography
                        variant="subtitle2"
                        className="text-secondaryText-600 italic text-xs font-normal"
                      >{`Serving Size: ${recipe.sections[currentSection].servingSize}`}</Typography>
                    )}
                </div>
                <div className="flex flex-col justify-between items-end w-1/2">
                  {recipe.sections[currentSection].macros &&
                  recipe.sections[currentSection].macros.length > 0 &&
                  recipe.sections[currentSection].macros[0] &&
                  recipe.sections[currentSection].macros[0].name === "Calories" ? (
                    <Typography variant="subtitle2" className="font-normal leading-snug text-xs text-secondaryText-600">
                      <span className="font-medium">{recipe.sections[currentSection].macros[0].amount}</span>{" "}
                      kcal/serving
                    </Typography>
                  ) : (
                    <div></div>
                  )}
                  {recipe.sections[currentSection].macros && recipe.sections[currentSection].macros.length > 0 && (
                    <Button className="p-0 hover:bg-transparent" disableRipple onClick={() => setShowMacrosModal(true)}>
                      <Typography variant="subtitle2" className="text-mainGreen leading-snug text-xs font-normal">
                        View Macros
                      </Typography>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="px-4 md:px-8 py-4">
        <ul className="ml-2 pl-0 lg:min-w-[400px] xl:min-w-[500px]">
          {recipe &&
          currentSection !== null &&
          recipe?.sections[currentSection]?.ingredients &&
          recipe?.sections[currentSection]?.ingredients.length > 0 ? (
            recipe.sections[currentSection].ingredients.map((ingredient, i) => {
              return (
                <Ingredient
                  section={recipe.sections[currentSection]}
                  sectionIndex={currentSection}
                  ingredient={ingredient}
                  key={i}
                  index={i}
                />
              );
            })
          ) : (
            <li className="py-4">
              <Typography>No ingredients in this section</Typography>
            </li>
          )}
        </ul>
      </div>
      <>{console.log(recipe?.sections[currentSection]?.output)}</>
    </div>
  );
};

SectionIngredients.propTypes = {
  walkthrough: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps)(SectionIngredients);
