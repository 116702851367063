import React from "react";
import ReactPlayer from "react-player";
import { Typography } from "@mui/material";

const VideoPlayer = ({ url, controls = true, playing = false }) => {
  const canPlayVideo = ReactPlayer.canPlay(url);

  if (!canPlayVideo) {
    return (
      <div
        className="w-full mb-4 flex items-center justify-center"
        style={{ position: "relative", paddingTop: "56.25%", borderRadius: "16px" }}
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <Typography variant="body2" className="text-secondaryText">
            This video cannot be embedded.
            <br />
            Please check the URL and try again.
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full mb-4" style={{ position: "relative", paddingTop: "56.25%", borderRadius: "16px" }}>
      <ReactPlayer
        url={url}
        controls={controls}
        playing={playing}
        width="100%"
        height="100%"
        style={{ position: "absolute", top: 0, left: 0 }}
      />
    </div>
  );
};

export default VideoPlayer;
